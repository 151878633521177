import React, { useState, useContext, useEffect } from 'react'
import '../css/footer.scss'
import { Link, useNavigate } from 'react-router-dom'
import '../css/responsive/footer_res.scss'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { CiCirclePlus, CiCircleMinus } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { UserContext } from '../context/userContext';
import axios from 'axios';

export default function Footer() {
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded({ ...expanded, [panel]: isExpanded });
    };
    let { baseURL, token, updateHeaderData } = useContext(UserContext);
    const navigate = useNavigate();

    const [category, setCategory] = useState([])
    useEffect(() => {
        axios.get(`${baseURL}/categories/parent`, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => {
                setCategory(response.data.data);
                // 
            })
            .catch((error) => {  })
    }, [baseURL, token])

    //----------------------------------- select cate---------------------------------------------------
    const handleSelectCate = (cate) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        navigate(`/products/category/${cate.id}`, { state: { cate } })
    }
    // -------------------------------------------------------------------------------------------------------------------------
    const moveToTop = () => {
        window.scrollTo(0, 0);
    }
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleImageClick = () => {
        if (validateEmail(email)) {
            axios.post(`${baseURL}/email/subscribe`, {   email } )
                .then(response => {
                    setMessage('Subscription successful!');

                    setTimeout(() => {
                        setMessage('');

                    }, 3000);
                })
                .catch(error => {
                    setMessage('Error subscribing email.');

                    setTimeout(() => {
                        setMessage('');

                    }, 3000);
                });
        } else {
            setMessage('Please enter a valid email address.');
            setTimeout(() => {
                setMessage('');

            }, 3000);
        }
    };

    const validateEmail = (email) => {
        // Simple email validation regex
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    return (
        <div className='footer'>

            <div className="footer-wrapper">
                <div className="footer-abs-imgs">
                    <div className="footer-img1">
                        <img src=
                            {`${process.env.PUBLIC_URL}/assests/footer-left.jpeg`} alt="" />
                    </div>
                    <div className="footer-img2">
                        <img src=
                            {`${process.env.PUBLIC_URL}/assests/footer-mid.jpeg`} alt="" />
                    </div>
                    <div className="footer-img3">
                        <img src=
                            {`${process.env.PUBLIC_URL}/assests/footer-right.jpeg`} alt="" />
                    </div>
                    <div className="footer-img4">
                        <div className="footer-rel">
                            <div className="modal-lizzard">
                                <img src=
                                    {`${process.env.PUBLIC_URL}/assests/modal-lizzard.svg`} alt="" />
                            </div>
                            <div className="footer-insta">
                                <a href="https://www.instagram.com/shopshortlisted" target='_blank'>
                                    <img src=
                                        {`${process.env.PUBLIC_URL}/assests/instagram.png`} alt="" />
                                </a>
                            </div>

                            <a href="https://www.instagram.com/shopshortlisted" target='_blank' className="footer-follow">
                                follow us on Instagram
                            </a>

                        </div>
                    </div>

                </div>




                <div className="footer-nav">
                    <div className="footer-short-listed">
                        <img src=
                            {`${process.env.PUBLIC_URL}/assests/short-listed-footer.png`} alt="" />
                    </div>
                    {/* <div className="footer-boston" id='footer-nav-907'>+1 (907) 159 - 6462</div> */}
                    <div className="footer-boston" id='footer-nav-boston'>Boston/West Palm Beach</div>

                    <div className="footer-boston" id='footer-nav-just'>support@shopshortlisted.com</div>
                    <div className="footer-flower-img4">
                        <img src=
                            {`${process.env.PUBLIC_URL}/assests/orange-hd.png`} alt="orange" />
                    </div>
                </div>

                <div className="footer-nav" id='footer-nav-support'>
                    <div className="footer-support">Support</div>
                    <Link to='/shipping' className="footer-boston" >Shipping Details</Link>
                    <Link to='/terms' className="footer-boston" >Terms Of Service</Link>
                    <Link to='/return' className="footer-boston" >Returns Policy</Link>
                    <Link to='/privacy' className="footer-boston" >Privacy Policy</Link>
                    <Link to='/refund' className="footer-boston" >Refund Policy</Link>
                    <Link to='/contact' className="footer-boston" >Contact Us</Link>
                    {/* <Link to='/' className="footer-boston" >About us</Link> */}
                    {/* <Link to='/faq' className="footer-boston" >FAQ</Link> */}

                </div>

                <div className="footer-nav" id='footer-nav-products'>
                    <div className="footer-support">Products</div>

                    {category && category.map((cat, index) =>
                        <div className="footer-boston" key={index} onClick={() => { handleSelectCate(cat) }}>{cat.name}</div>

                    )}
                    {/* <div className="footer-boston">Cosmetics</div>
                    <div className="footer-boston">Jewelry</div>
                    <div className="footer-boston">Closet</div>
                    <div className="footer-boston">Bags</div> */}
                </div>

                <div className="footer-nav">
                    <div className="footer-support">Subscribe to our Newsletter</div>
                    <div className="footer-boston" id='footer-new'>New Customer </div>
                    <div className="footer-input-cont">
                        <input className='footer-input' value={email}
                            onChange={handleEmailChange} type="email" name="email" id="" placeholder='E-mail address' />
                        <img src=
                            {`${process.env.PUBLIC_URL}/assests/footer-arrow.svg`} alt="" className='footer-input-img' onClick={handleImageClick} />
                    </div>
                    {message && (
                        <div className={` `}>
                            {message}
                        </div>
                    )}
                    <div className="footer-details">
                        <div className="footer-details-orange-loc">
                            <div className="">
                                {/* <div className="footer-boston" >+1 (907) 159 - 6462</div> */}
                                <div className="footer-boston" >Boston/West Palm Beach</div>
                            </div>
                            <img src= {`${process.env.PUBLIC_URL}/assests/orange-hd.png`} alt="orange" style={{ width: '120px' }} />
                        </div>
                        <div className="footer-boston" >support@shopshortlisted.com</div>
                    </div>
                    <div className="footer-social">
                        <div className="footer-social-flex">
                            <a href="https://www.instagram.com/shopshortlisted" target='_blank'>
                                <img src=
                                    {`${process.env.PUBLIC_URL}/assests/footer-instagram.png`} alt="" />
                            </a>
                            <a href="https://www.tiktok.com/@shopshortlisted" target='_blank'>
                                <img src=
                                    {`${process.env.PUBLIC_URL}/assests/footer-tick.png`} alt="" />
                            </a>
                        </div>
                        <div className="footer-boston2">  Join our social channels</div>
                    </div>
                    <div className="footer-accordion" id='footer-acc'>
                        <Accordion onChange={handleAccordionChange('panel6')} expanded={expanded['panel6']}>
                            <AccordionSummary
                                expandIcon={expanded['panel6'] ? <IoIosArrowDown className='FAQ-accordion-plus' id='footer-down' /> : <IoIosArrowDown className='FAQ-accordion-plus' id='footer-up' />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <div className="privacy-contact3" id='footer-support'>
                                    Support
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="footer-acc-nav">
                                    <Link to='/shipping' className="footer-boston" >Shipping Details</Link>
                                    <Link to='/terms' className="footer-boston" >Terms Of Service</Link>
                                    <Link to='/return' className="footer-boston" >Returns Policy</Link>
                                    <Link to='/privacy' className="footer-boston" >Privacy Policy</Link>
                                    <Link to='/refund' className="footer-boston" >Refund Policy</Link>
                                    <Link to='/contact' className="footer-boston" >Contact Us</Link>
                                    {/* <Link to='/' className="footer-boston" >About us</Link> */}
                                    {/* <Link to='/faq' className="footer-boston" >FAQ</Link> */}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="footer-accordion">
                        <Accordion onChange={handleAccordionChange('panel7')} expanded={expanded['panel7']}>
                            <AccordionSummary
                                expandIcon={expanded['panel7'] ? <IoIosArrowDown className='FAQ-accordion-plus' id='footer-down' /> : <IoIosArrowDown className='FAQ-accordion-plus' id='footer-up' />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <div className="privacy-contact3" id='footer-support'>
                                    Products
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="footer-acc-nav">
                                    {category && category.map((cat, index) =>
                                        <div className="footer-boston" key={index} onClick={() => { handleSelectCate(cat) }}>{cat.name}</div>

                                    )}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>


                    <div className="footer-boston3">We accept</div>

                    <div className="footer-cash-methods">
                        <img src=
                            {`${process.env.PUBLIC_URL}/assests/footer-visa.png`} alt="" />
                        <img src=
                            {`${process.env.PUBLIC_URL}/assests/footer-master.png`} alt="" />
                        <img src=
                            {`${process.env.PUBLIC_URL}/assests/footer-express.png`} alt="" />
                        {/* <img src=
                            {`${process.env.PUBLIC_URL}/assests/footer-pay.png`} alt="" />
                        <img src=
                            {`${process.env.PUBLIC_URL}/assests/footer-pal.png`} alt="" /> */}
                        <img src=
                            {`${process.env.PUBLIC_URL}/assests/footer-discover.png`} alt="" />
                        {/* <img src=
                            {`${process.env.PUBLIC_URL}/assests/footer-shop.png`} alt="" /> */}
                    </div>
                </div>






            </div>
            <div className="footer-brown-line"></div>
            <div className="d-flex align-items-center justify-content-center">
                <a href="https://msol.dev/" target='_blank' className="footer-copy">
                    @ 2024 Shortlisted | Site by Msol.Dev
                </a>
            </div>
        </div>
    )
}
