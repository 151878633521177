import React, { useEffect, useState, useContext } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFlip, Autoplay,Pagination } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

import '../css/cart.scss';
import { UserContext } from '../context/userContext';
import axios from 'axios';
import CartProduct from './cart/cartProduct';
import { Link, useNavigate } from 'react-router-dom'
import '../css/responsive/cart_res.scss';
import { CartContext } from '../context/cartContext';

export default function Cart() {
    const {subTotal,cartLength,sesId,loadIcon} = useContext(CartContext);
    const navigate = useNavigate();

    let { baseURL, token, defaultImage } = useContext(UserContext);
    const [relatedProducts, setRelatedProducts] = useState([]);

    useEffect(() => {
        const config = token
        ? { headers: { 'Authorization': `Bearer ${token}` } }
        : { params: { session_id: sesId } };
        axios.get(`${baseURL}/cart/related_products`, config)
            .then((response) => {
                if (response.data && response.data.data) {
                    setRelatedProducts(response.data.data);
                } else {
                   
                }
            })
            .catch((error) => {  })

    }, [baseURL, token])

    const handleImageError = (e) => {
        e.target.src = defaultImage;
    };

    return (
        <>
        {/* {loadIcon && (
            <div className="loading-container">
                <img src={`${process.env.PUBLIC_URL}/assests/loading-icon.gif`} alt="Loading..." className="checkout-video" />
            </div>
        )} */}
        <div className={`privacy `}>

            <div className="privacy-wrapper">
                <div className="privacy-privacy">CART</div>
                <div className="login-please">You are eligible for free shipping!</div>
                <div className="cart-black-line"></div>

                <div className="cart-right-left">
                    <div className="cart-left">
                        <div className="cart-product-subtotal">
                            <div className="cart-product">PRODUCT</div>
                            <div className="cart-product">SUBTOTAL</div>
                        </div>


                        <CartProduct />




                        {/*  */}
                    </div>
                    {cartLength >= 1 && (
                    <div className="cart-right">
                        <div className="cart-right-rect">
                            <div className="cart-bird">
                                <img src="./assests/cart-bird.svg" alt="" />
                            </div>
                            <div className="cart-total-130">
                                <div className="cart-total">TOTAL</div>
                                <div className="cart-total">${parseFloat(subTotal).toFixed(2)} USD</div>
                            </div>
                            <div className="cart-shipping">Shipping & taxes calculated at checkout</div>
                            {/* <div className="cart-add">Add order note <span className='cart-shipping'>(optional)</span></div> */}
                            {/* <textarea name="" id="" className='cart-textarea' placeholder='How can we help you?'></textarea> */}
                            <Link to='/checkout' className="cart-checkout">CHECKOUT</Link>

                        </div>
                        <div className="cart-we">We accept</div>
                        <div className="footer-cash-methods mt-2">
                            <img src="./assests/footer-visa.png" alt="" />
                            <img src="./assests/footer-master.png" alt="" />
                            <img src="./assests/footer-express.png" alt="" />
                            {/* <img src="./assests/footer-pay.png" alt="" />
                            <img src="./assests/footer-pal.png" alt="" /> */}
                            <img src="./assests/footer-discover.png" alt="" />
                            {/* <img src="./assests/footer-shop.png" alt="" /> */}
                        </div>
                    </div>
                    )}
                </div>
            </div>






            <div className="details-wrapper-2">
                <div className="details-new-arrival-back-front-imgs">


                    <div className="home-new-arrival" id='cart-new-arrival'>
                        You May Also Like
                    </div>

                    {/* {relatedProducts.length > 0 && ( */}
                        <div className="home-back-front-imgs" id='cart-home-back-front-imgs'>
                            <div className="home-back-img swiper-backk" >
                                <img src="./assests/home-back.png" alt="" className='swiper-back' />
                                <img src="./assests/home-front.png" alt="" className="swiper-back-hover" />
                            </div>
                            <div className="home-front-img swiper-frontt" >
                                <img src="./assests/home-back.png" alt="" className='swiper-front' />
                                <img src="./assests/home-front.png" alt="" className="swiper-front-hover" />
                            </div>
                        </div>
                    {/* )} */}
                </div>
                <div className="home-swipers home-pag">
                    <Swiper
                        className="mt-4 pb-5"
                        spaceBetween={10}
                        slidesPerView={1}
                        navigation={{
                            nextEl: ".swiper-frontt",
                            prevEl: ".swiper-backk",
                        }}
                        modules={[Navigation, Autoplay,Pagination]}
                        
                        // autoplay={{ delay: 2000 }}
                        speed={2000}
                        pagination={{
                            clickable: true,

                        }}
                        breakpoints={{
                            // when window width is >= 640px
                            600: {
                                slidesPerView: 2,
                            },
                            900: {
                                slidesPerView: 3,
                            }

                        }}
                    >
                        {relatedProducts.map((relatedProduct) => (
                          
                                <SwiperSlide key={relatedProduct.id}>
                                    <div className="home-products-swiper2" onClick={() => { navigate(`/details/${relatedProduct.id}`); }}>
                                        <div className="details-menu">
                                            <div className="details-swiper-img-1">
                                                <img src={relatedProduct.images[0].image} alt={relatedProduct.name} onError={handleImageError} />
                                            </div>
                                            <div className="home-golden">{relatedProduct.name}</div>
                                            <div className="home-price">${relatedProduct.price}</div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            
                        ))}
                    </Swiper>
                </div>
            </div>

        </div>
        </>
    )
}
