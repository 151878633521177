import React, { useState, useEffect, useContext } from 'react'
import '../css/designers.scss'
import '../css/responsive/designers_res.scss'
import axios from 'axios'
import { UserContext } from '../context/userContext';
import { useNavigate } from 'react-router-dom';

export default function Designers() {
    let { baseURL, token ,updateHeaderData } = useContext(UserContext);
    const [designers, setDesigners] = useState([]);

    useEffect(() => {
        axios.get(`${baseURL}/brands`, {
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then((response) => {
                if (response.data && response.data.data) {
                    setDesigners(response.data.data);
                    
                } else {
                   
                }
            })
            .catch( )
    }, [baseURL, token]);

// ------------------------------------------------------------------------------------------------------------------------------
const navigate =useNavigate()
const handleSelectBrand = ( brand) => {
    navigate(`/products/designers/${brand.id}`)
    // updateHeaderData('brand', brand)
    // navigate('/shop')
};

// ------------------------------------------------------------------------------------------------------------------------------
    return (
        <div className='privacy'>
            <div className="designers-wrapper">
                <div className="privacy-privacy">DESIGNERS</div>
                <div className="designers-discover">
                    Discover {designers ? designers.length : 0} of the world's top designers and emerging talent.
                </div>
                <div className="designers-imgs">
                    {designers && designers.map((designer, index) => (
                        <div className="designer-img-text" onClick={() => handleSelectBrand(designer)}>
                            <div className="designers-img">
                                <img src={designer.image} alt="" />
                            </div>
                            <div className="designers-rebel">{designer.name}</div>
                            <div className="designers-123">({designer.products})</div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}
