import React from 'react'
// import { useOutletContext } from 'react-router-dom';

export default function Details_Details({ details }) {
  // const details = useOutletContext({ details });

  return (
    <div className='details-Details'>

<div className="details-match" dangerouslySetInnerHTML={{ __html: details.details }}>
                {/* Match our most-loved beaded pieces with your bubbly personality. Handcrafted in 14k solid gold featuring <p>a responsibly sourced diamond. This one’s an heirloom in the making.</p> */}
            </div>
      {/* <ul className='details-ul'>
        <li className='details-li2'>Average Carat Weight: 0.52 ct</li>
        <li className='details-li'>Diamond Clarity: SI 1-2</li>
        <li className='details-li'>Diamond Color: G-H</li>
        <li className='details-li'>Stone Size: 5 mm</li>
        <li className='details-li'>Shank Width: 2 mm</li>
        <li className='details-li'>This style comes with a GIA® Report, which gives a full assessment of the diamond quality and authenticates the characteristics (including cut, color, clarity, and carat weight). The GIA® Report is unique to each individual diamond and must accompany the style if returned.</li>
      </ul>
      <div className="details-material">Materials</div>
      <div className="details-14"><img className='details-star' src=
        {`${process.env.PUBLIC_URL}/assests/galaxy-star.svg`} alt="" /> 14k Solid Gold</div>
      <div className="details-li">Our 14k solid gold pieces are made to last forever. 14k gold will not oxidize or discolor, so you can wear your jewelry every day.</div>

      <div className="details-14"><img className='details-star' src=
        {`${process.env.PUBLIC_URL}/assests/gem.svg`} alt="" />Natural Diamonds</div>
      <div className="details-li">Our natural diamonds are responsibly sourced from suppliers who follow conflict-free and socially responsible practices.</div> */}
    </div>
  )
}
