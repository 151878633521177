import React, { useContext, useState } from 'react'
import '../css/login.scss'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { UserContext } from '../context/userContext'
import { useNavigate } from "react-router-dom";
import '../css/responsive/login_res.scss'

export default function Login() {

    const navigate = useNavigate();
    let { baseURL, login } = useContext(UserContext)
    const [error, setError] = useState('')
    const [form, setForm] = useState({
        email: '',
        password: ''
    })

    // ------------------  update the form input ------------------
    const ChangeForm = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        })
    }
    // ------------------  call login api ------------------
    const HandelLogin = () => {
        axios.post(`${baseURL}/login`, form)
            .then((response) => {
                login(response.data);
                navigate("/");
              

                // setError('')
            })
            .catch((error) => { setError(error) })
    }

    return (
        <div className='privacy'>
            {/* <div className="login-flower-img">
                <img src="./assests/login-flower.svg" alt="" />
            </div> */}
            <div className="recover-wrapper">
                <div className="privacy-privacy" id='login-login'>Login</div>
                <div className="login-please">Please enter your e-mail and password:</div>
                <input type="text" className='login-input' placeholder='Name*' name='email' value={form.email} onChange={ChangeForm} />
                <div className="login-pass-cont">
                    <input type="password" className='login-input3' placeholder='password' name='password' value={form.password} onChange={ChangeForm} />
                    <Link to='/recover' className="login-forget">Forgot password?</Link>
                </div>
                <div className="login-send" onClick={HandelLogin}>LOGIN</div>
                {error && <div className="login-please text-danger">Invalid credentials</div>}

                <div className="login-please">New Customer? <span> <Link to='/register' className='login-span'> Create an account</Link></span></div>

            </div>
        </div>
    )
}
