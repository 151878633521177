import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { IoCloseOutline } from "react-icons/io5";
import { Dropdown } from 'primereact/dropdown';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import USStates from '../../constants/USStates';


export default function AddressModal({ show, handleClose, handleAddCard, errors }) {

    const [form, setForm] = useState({
        first_name: '',
        last_name: '',
        address: '',
        company: '',
        phone: '',
        apartment: '',
        state: '',
        zipcode: '',
        country: ' America',
        city: '',
        default: '',
    })

    function HandleChangeForm(e) {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        })
    }

    return (

        <div>
            <Modal show={show} onHide={handleClose} className="custom-modal custom-mod">

                <Modal.Body>
                    <img src="/assests/addressModal-flower.svg" alt="" className='addressModal-flower'/>
                    <div className=" addressModal-add-close">
                        <div className="addressModal-add">Add a new address</div>
                        <IoCloseOutline className='modal-close' onClick={handleClose} />
                    </div>
                    <div className="addressModal-body-cont">
                        <div className="addressModal-add-new-mobile">

                            Add a new address
                        </div>
                        <div className="addressModal-please">Please fill in the fields below.</div>

                        <div className=" addressModal-fst-lst">
                            <input type="text" placeholder='First name' className='addressModal-input2' name="first_name" onChange={HandleChangeForm} />
                            <input type="text" placeholder='Last name' className='addressModal-input3' name="last_name" onChange={HandleChangeForm} />
                        </div>
                        <div className="addressModal-fst-lst">
                            {errors.first_name && <div className='error'>{errors.first_name[0]}</div>}
                            {errors.last_name && <div className='error'>{errors.last_name[0]}</div>}
                        </div>

                        <input type="text" placeholder='Company (optional)' className='addressModal-input' name="company" onChange={HandleChangeForm} />
                        {errors.company && <div className='error'>{errors.company[0]}</div>}

                        <input type="text" placeholder='Address' className='addressModal-input' name="address" onChange={HandleChangeForm} />
                        {errors.address && <div className='error'>{errors.address[0]}</div>}

                        <input type="text" placeholder='Apartment, suite, etc. (optional)' className='addressModal-input' name="apartment" onChange={HandleChangeForm} />
                        {errors.apartment && <div className='error'>{errors.apartment[0]}</div>}

                        <input type="text" placeholder='City ' className='addressModal-input' name="city" onChange={HandleChangeForm} />

                        <div className="addressModal-fst-lst  ">
                            <Dropdown value={form.state} onChange={(e) => setForm({ ...form, state: e.value })} options={USStates} optionLabel="name"
                                placeholder="Select a state" className="addressModal-input2" checkmark={true} highlightOnSelect={false} name='state' />

                            <input type="text" placeholder='ZIP code' className='addressModal-input2' name="zipcode" onChange={HandleChangeForm} />
                        </div>
                        <div className="addressModal-fst-lst">
                            {errors.state && <div className='error'>{errors.state[0]}</div>}
                            {errors.zipcode && <div className='error'>{errors.zipcode[0]}</div>}

                        </div>

                        <input type="text" placeholder='Phone ( optional)' className='addressModal-input' name="phone" onChange={HandleChangeForm} />
                        {errors.phone && <div className='error'>{errors.phone[0]}</div>}

                        <div className=" addressModal-check-save">
                            <input type="checkbox" name="default" onChange={HandleChangeForm} />
                            <div className="addressModal-set"> Set as default</div>
                        </div>
                        <div className="addressModal-save" onClick={() => { handleAddCard(form); }}>SAVE</div>
                    </div>
                </Modal.Body>

            </Modal>

        </div>
    )
}
