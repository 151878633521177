import React, { useContext, useState } from 'react'
import '../css/register.scss'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import { UserContext } from '../context/userContext';
import '../css/responsive/register_res.scss'
export default function Register() {
    const navigate = useNavigate();
    let { baseURL , login } = useContext(UserContext)
    const [error, setError] = useState({
        first_name:'',
        last_name:'',
        email:'',
        password:'',
        password_confirmation:'',
    })
    const [form, setForm] = useState({
        first_name:'',
        last_name:'',
        email:'',
        password:'',
        password_confirmation:'',
    })

    // ------------------  update the form input ------------------
    const UpdateForm=(e)=>{
        const {name,value}= e.target;
        setForm({
            ...form,
            [name]:value
        })
    }
    // ------------------  call register api ------------------
    const HandelRegister=()=>{
        axios.post(`${baseURL}/register`,form)
        .then((response)=>{
            if(response.data.success){
                login(response.data);
                navigate("/");
            }else{
                setError(response.data.data)
            }
        })
        .catch()
    }


    return (
        <div className='privacy'>

            <div className="recover-wrapper">
                <div className="register-register-bird">
                    <div className="register-register-please">
                        <div className="register-privacy">The Shortlist</div>
                        <div className="login-please">Please fill in the fields below:</div>
                    </div>
                    <img src="./assests/register-bird.svg" className='register-bird' alt="" />
                </div>

                <input type="text" className='login-input' placeholder='First name' name='first_name' onChange={UpdateForm} />
                {error.first_name && <div className="error-msg">{error.first_name[0]}</div>}

                <input type="text" className='login-input2' placeholder='Last name' name='last_name' onChange={UpdateForm} />
                {error.last_name && <div className="error-msg">{error.last_name[0]}</div>}

                <input type="email" className='login-input2' placeholder='Email' name='email' onChange={UpdateForm} />
                {error.email && <div className="error-msg">{error.email[0]}</div>}


                <input type="password" className='login-input2' placeholder='password' name='password' onChange={UpdateForm} />
                {error.password && <div className="error-msg">{error.password[0]}</div>}


                <input type="password" className='login-input2' placeholder='Confirm Password' name='password_confirmation' onChange={UpdateForm} />

                <div className="login-send" onClick={HandelRegister}>CREATE ACCOUNT</div>
                <div className="login-please">Already have an account? <span> <Link to='/login' className='login-span'> Login</Link></span></div>

            </div>

                <img src="./assests/register-heart1.svg" alt="" className='register-heart2'/>
                <img src="./assests/register-heart2.svg" alt="" className='register-heart1'/>



        </div>
    )
}
