import React, { useState, useEffect, useContext } from "react";
import '../../css/shop.scss'
import '../../css/responsive/shop_res.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/autoplay';
import ShopFilter from "./shopFilter";
import ShopProduct from "./shopProduct";
import { UserContext } from "../../context/userContext";
import axios from "axios";
import { Paginator } from 'primereact/paginator';


export default function Shop() {
    const [isWindowClicked, setIsWindowClicked] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [checkedItems, setCheckedItems] = useState([]);
    const [noProduct, setNoProduct] = useState(false);

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex);
    };
    const handleCheckboxChange = (itemName) => {
        const isChecked = checkedItems.includes(itemName);
        if (isChecked) {
            setCheckedItems(checkedItems.filter(item => item !== itemName));
        } else {
            setCheckedItems([...checkedItems, itemName]);
        }
    };

    // -----------------------------------------------------------------------------------------------------------
    let { baseURL, token, isfromBrandHeader, GetShopSettings, shopSetting, searchInput, exclusive, setExclusive, headerDate, updateHeaderData } = useContext(UserContext);
    const [hasMounted, setHasMounted] = useState(false);
    const [products, setProducts] = useState([])
    const [fixedSlider, setFixedSlider] = useState(null)
    const [filter, setFilter] = useState({ categories: '', brands: '', colors: '', sizes: '', min: 0, max: 0, })
    const [filterObj, setFilterObj] = useState({ colors: '', from: '', to: '', brand: '', category: [], sort: '', search: searchInput, shortlisted_flag: headerDate?.exclusive ?? 0, collection_id: headerDate?.collection?.id ?? 0 })
    const [cateHeader, setCateHeader] = useState('');
    const [isFromCate, setIsFromCate] = useState(false);



    //--------------------- toggle filter section ----------------------------------------------
    const isMobileView = () => window.innerWidth <= 662;
    const [isFilterVisible, setIsFilterVisible] = useState(!isMobileView());
    const toggleFilterVisibility = () => {
        setIsFilterVisible(!isFilterVisible);
    };
    //--------------------- Update products based on search ----------------------------------------------
    useEffect(() => {
        if (hasMounted) {
            setFilterObj({ ...filterObj, search: searchInput })
        }
    }, [searchInput])
    //--------------------- Update products after toggle favourites ----------------------------------------------
    const handelUpdateProducts = (new_products) => {
        setProducts(new_products)
    }
    //--------------------- Update filter object  ----------------------------------------------
    const handleFilterObj = (name, value) => {
        if (name === 'clear') {
            setFilterObj({ ...filterObj, colors: '', from: '', to: '', category: [], sort: '' })
        }
        else if (name === 'priceRange') {
            setFilterObj({
                ...filterObj,
                from: value[0],
                to: value[1],
            })
        } else {
            setFilterObj({
                ...filterObj,
                [name]: value
            })
        }

    }
    //------------------------ for pagination -------------------------------------------
    const [pagination, setPagination] = useState({ first: 0, from: 0, to: 0, totalRecords: 0, rows: 12 })
    useEffect(() => {
        setIsFromCate(false);
        if (hasMounted && !isFromCate) {
            fetchProducts(pagination.first / pagination.rows + 1);
        }
    }, [pagination.first, pagination.rows, filterObj]);

    useEffect(() => {

        const prevButton = document.querySelector('.p-paginator-prev');
        const nextButton = document.querySelector('.p-paginator-next');
        
        if (prevButton) {
            prevButton.addEventListener('click', handleScrollToTop);
        }

        if (nextButton) {
            nextButton.addEventListener('click', handleScrollToTop);
        }


    }, [pagination]);
    const handleScrollToTop = () => {
        
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 0); 
    };

    const onPageChange = (e) => {
        setPagination({ ...pagination, first: e.first, rows: e.rows })
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    //-----------------  get categories and brands and products  -----------------
    const submitFilter = () => {
        fetchProducts(pagination.first / pagination.rows + 1);
    }

    //-----------------  get categories and brands and products  -----------------
    useEffect(() => {
        setHasMounted(true);
        GetShopSettings()
        //-----------------  get brands -----------------
        axios.get(`${baseURL}/brands`, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => setFilter(prev => ({ ...prev, brands: response.data.data })))
            .catch()
        //-----------------  get categories -----------------
        axios.get(`${baseURL}/categories`, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => setFilter(prev => ({ ...prev, categories: response.data.data })))
            .catch( )
        //-----------------  get products -----------------
        const queryParams = new URLSearchParams({ page: 0, ...filterObj }).toString();
        setProducts([]);
        axios.get(`${baseURL}/products?${queryParams}`, { headers: { 'Authorization': `Bearer ${token}` } })
            .then(response => {
                const data = response.data.data;
                // if(data.data.length <= 0){
                //     setNoProduct(true)
                // }
                setProducts(data.data);
                setPagination({
                    ...pagination,
                    totalRecords: data.total,
                    from: data.from,
                    to: data.to,
                    rows: data.per_page
                });
                setFilter(prev => ({
                    ...prev,
                    colors: response.data.colors,
                    sizes: response.data.sizes,
                    min: response.data.min_price,
                    max: response.data.max_price,
                }))
                setFixedSlider({ min: response.data.min_price, max: response.data.max_price })
            })
            .catch( )
    }, [])

    //----------------------  get products (filter / pagination) -----------------
    const fetchProducts = (page) => {
        const queryParams = new URLSearchParams({
            page: page,
            ...filterObj
        }).toString();
        setProducts([]);
        axios.get(`${baseURL}/products?${queryParams}`, { headers: { 'Authorization': `Bearer ${token}` } })
            .then(response => {
                const data = response.data.data;
                if (data.data.length <= 0) {
                    setNoProduct(true)
                } else {
                    setNoProduct(false)
                }

                setProducts(data.data);
                setPagination({
                    ...pagination,
                    totalRecords: data.total,
                    from: data.from,
                    to: data.to,
                    rows: data.per_page
                });
                setFilter(prev => ({
                    ...prev,
                    colors: response.data.colors,
                    sizes: response.data.sizes,
                    min: response.data.min_price,
                    max: response.data.max_price,
                }))
            })
            .catch( )
    };

    //----------------------  select cate from header --------------------------------------------------
    const selectCate = (cat) => {
        // updateHeaderData('category', cat.id)
        updateHeaderData('category', cat)
        //  setCateHeader(cat);
    }
    //----------------------  get the shop title --------------------------------------------------------
    const getShopTitle = () => {
        if (headerDate && headerDate.collection) {
            return headerDate.collection.name + " " + 'Collection'
        } else if (headerDate && headerDate.exclusive) {
            return 'Only on Shortlisted'  //changed name
        } else if (headerDate && headerDate.brand) {
            return headerDate.brand.name
        } else if (headerDate && headerDate.category) {
            return headerDate.category.name
            // }else if(cateHeader){
            //     return cateHeader.name
        }
        else {
            return 'All Items';
        }
    };

    //------------------------ get products from navbar  -------------------------------------------
    useEffect(() => {
        if (hasMounted) {
            if (headerDate?.category) {
                setIsFromCate(true)
            } else {
                setIsFromCate(false)
            }
            if (headerDate !== null) {
                setFilterObj(prevFilterObj => ({
                    ...prevFilterObj,
                    brand: headerDate?.brand?.id ?? '',
                    shortlisted_flag: headerDate?.exclusive ?? 0,
                    collection_id: headerDate?.collection?.id ?? 0,
                    category: headerDate?.category ?? [],
                    search: headerDate?.search ?? '',
                    sort: 'asc',
                    sizes: '',
                    colors: '',
                    from: '',
                    to: '',

                }));
                //   fetchProducts(pagination.first / pagination.rows + 1);
            }
            if (headerDate && headerDate.category) {
                setCateHeader(headerDate.category);
            }
        }
    }, [headerDate]);
    const hasImages = shopSetting.images && shopSetting.images.length > 0;
    {/* ------------------------------------------------------------------------------------------------ */ }
    return (
        <div className='address'>
            {(getShopTitle() === 'All Items' || (headerDate && headerDate.category)) && <div className='grey_nav'>
                <div className="grey_nav-wrapper">
                    {filter.categories &&
                        filter.categories.map((cate, index) => (
                            <div key={cate.id}>
                                <div className="d-flex">
                                    <div className={`grey_nav-nav ${cate.id === cateHeader.id ? 'selected_cate' : ''}`} onClick={() => { selectCate(cate) }}>{cate.name}</div>
                                    {index < filter.categories.length - 1 && <div className="grey_nav-break "></div>}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            }
            {/* ------------------------ for brand title ----------------------------------------- */}
            {headerDate && headerDate.brand && <div className='grey_nav grey_nav2'>
                <div className="grey_nav3">
                    <div className="grey_nav-wrapper grey_nav-wrapper2">
                        <div className={`grey_nav-nav`} >Designers</div>
                        <div className="grey_nav-break "></div>
                        <div className={`grey_nav-nav special-title `} >{headerDate.brand.name}</div>
                    </div>
                </div>
            </div>
            }
            {/* ------------------------ for collection title ----------------------------------------- */}
            {headerDate && headerDate.collection && <div className='grey_nav grey_nav2'>
                <div className="grey_nav3">
                    <div className="grey_nav-wrapper grey_nav-wrapper2">
                        <div className={`grey_nav-nav`} >Shortlisted Stories</div>
                        <div className="grey_nav-break "></div>
                        <div className={`grey_nav-nav special-title `} >{headerDate.collection.name}</div>
                    </div>
                </div>
            </div>
            }
            {/* ------------------------ for Exclusives title ----------------------------------------- */}
            {headerDate && headerDate.exclusive && <div className='grey_nav grey_nav2'>
                <div className="grey_nav3">
                    <div className="grey_nav-wrapper grey_nav-wrapper2">
                        <div className={`grey_nav-nav`} >Shortlisted Exclusives</div>
                        <div className="grey_nav-break "></div>
                        <div className={`grey_nav-nav special-title`} >Exclusives</div>
                    </div>
                </div>
            </div>
            }
            <div className="shop-wrapper">
                <div className={`shop-wrapper-left-right ${isFilterVisible ? 'filter-visible' : ''}`}>
                    {(<div className="shop-wrapper-left">
                        <ShopFilter toggleFilterVisibility={toggleFilterVisibility} cateHeader={cateHeader} filter={filter} fixedSlider={fixedSlider} checkedItems={checkedItems} onCheckboxChange={handleCheckboxChange} handleFilterObj={handleFilterObj} isfromBrandHeader={isfromBrandHeader} exclusive={exclusive} setExclusive={setExclusive} submitFilter={submitFilter} />
                    </div>)}
                    <div className="shop-wrapper-right">
                        <div className="silder-container">
                            {shopSetting.images && <Swiper
                                className="shop"
                                spaceBetween={25}
                                slidesPerView={1}
                                navigation={{
                                    nextEl: ".home-right",
                                    prevEl: ".home-left",
                                }}
                                modules={[Navigation, Autoplay, Pagination]}
                              
                                // autoplay={{ delay: 2000 }}
                                speed={2000}
                                pagination={{
                                    clickable: true,
                                    bulletClass: "swiper-pagination-bullet",
                                    bulletActiveClass: "swiper-pagination-bullet-active",

                                }}
                                onSlideChange={handleSlideChange}
                                activeindex={activeIndex}
                            >
                                {shopSetting.images.map(slider => (<SwiperSlide key={slider.id}>
                                    <img src={slider.url} className="shop-main-img" alt="" />
                                </SwiperSlide>))}
                            </Swiper >}
                            <div className={`filter-icon ${hasImages ? '' : 'no-images'}`}>
                                <img src='./assests/filter-icon.png' onClick={toggleFilterVisibility} alt="" />
                            </div>
                        </div>
                        <div className="shop-necklace" >

                            {
                                getShopTitle()
                            }
                        </div>

                        {<ShopProduct products={products} pagination={pagination} handleFilterObj={handleFilterObj} isWindowClicked={isWindowClicked} setIsWindowClicked={setIsWindowClicked} handelUpdateProducts={handelUpdateProducts} />}
                        {noProduct && <div className="no-item"> No Item Found </div>}
                        {products.length > 0 && <div className="my-1">
                            <Paginator first={pagination.first} rows={pagination.rows} totalRecords={pagination.totalRecords} onPageChange={onPageChange} />
                        </div>}
                    </div>

                </div>
                {shopSetting.footer && <div className="shop-video">
                    <img src={shopSetting.footer} alt="" className="shop-arrow w-100 h-100" />
                </div>}
            </div>
        </div>



    )
}
