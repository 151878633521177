import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { UserContext } from '../context/userContext';
export const CartContext = createContext();
export const CartProvider = ({ children }) => {

    const [carts, setCarts] = useState([]);
    const [cartLength, setCartLength] = useState(null);
    const [cartNo, setcartNo] = useState(null);
    const [subTotal, setSubTotal] = useState(null);
    const [sesId, setSesId] = useState('');
    const [cartsNumber, setCartsNumber] = useState(0);

    let { baseURL, token } = useContext(UserContext);


    const getProductCount = (data) => {
        return data.reduce((totalCount, brand) => {
            const brandProductCount = brand.products ? brand.products.reduce((sum, product) => sum + product.quantity, 0) : 0;
            return totalCount + brandProductCount;
        }, 0);
    };

    const calculateSubTotal = (data) => {
        return data.reduce((total, brand) => {
            const brandTotal = brand.products ? brand.products.reduce((sum, product) => sum + (product.variation_attributes.price * product.quantity), 0) : 0;
            return total + brandTotal;
        }, 0);
    };
    //     const updateCartNo = (cartItems) => {
    //         const totalItems = cartItems.reduce((total, cart) => 
    //             total + cart.products.reduce((sum, product) => sum + product.quantity, 0), 0);
    //         setcartNo(totalItems);
    //     };




    // const fetchCartSession = () => {
    //     // Step 1: Check if session_id is already stored in localStorage
    //     const storedSessionId = localStorage.getItem('session_id');

    //     setSesId(storedSessionId);
    //     
    //     if (storedSessionId) {
    //         // Step 2: If session_id is found in localStorage, use it
    //         setSesId(storedSessionId);

    //     } else {
    //         // Step 3: If session_id is not found, fetch it from the server
    //         axios.get(`${baseURL}/session_id`, { withCredentials: true })
    //             .then((response) => {
    //                 const sessionId = response.data.session_id;
    //                 setSesId(sessionId);

    //                 // Step 4: Store the session_id in localStorage
    //                 localStorage.setItem('session_id', sessionId);
    //             })
    //             .catch((error) => );
    //     }
    // };
    const fetchCartCount = () => {
        const config = token
            ? { headers: { 'Authorization': `Bearer ${token}` } }
            : { params: { session_id: sesId } };

        axios.get(`${baseURL}/cart/count`, config)
            .then((response) => {
                if (response.data && response.data.data) {
                    setcartNo(response.data.data);
                } else {

                }
            })
            .catch();
    };
    // useEffect(() => {
    //     fetchCartSession();

    // }, []);
    // useEffect(() => {
    //     if ( sesId) {
    //     fetchCartCount();
    //     }

    // }, [baseURL, token,sesId]);


    useEffect(() => {
        setCartLength(getProductCount(carts));
        setSubTotal(calculateSubTotal(carts));        // updateCartNo(carts);
    }, [carts])
    const [loadIcon, setLoadIcon] = useState(true);

    const fetchCartSession = async () => {
        // Step 1: Check if session_id is already stored in localStorage
        const storedSessionId = localStorage.getItem('session_id');

        if (storedSessionId) {
            // Step 2: If session_id is found in localStorage, use it
            setSesId(storedSessionId);
        } else {
            // Step 3: If session_id is not found, fetch it from the server
            try {
                const response = await axios.get(`${baseURL}/session_id`, { withCredentials: true });
                const sessionId = response.data.session_id;

                // Step 4: Store the session_id in localStorage and set it in state
                localStorage.setItem('session_id', sessionId);
                setSesId(sessionId);
            } catch (error) {

            }
        }
    };

    useEffect(() => {
        fetchCartSession();
    }, []);  // This runs once when the component mounts to fetch the session ID

    const getCart = async () => {
        setLoadIcon(true);

        try {
            let response;

            if (token) {
                // If the user is logged in, use the token for authorization
                response = await axios.get(`${baseURL}/orders/checkout`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
            } else if (sesId) {
                // If not logged in, use the session ID
                response = await axios.get(`${baseURL}/orders/checkout`, {
                    params: { session_id: sesId }
                });
            }

            if (response) {
                setSubTotal(response.data.total_amount);
                setCarts(response.data.data);
            }
        } catch (error) {

        } finally {
            setLoadIcon(false);
        }
    };

    useEffect(() => {
        if (sesId || token) {
            getCart();
        }
    }, [baseURL, token, sesId]);

    const handleIncrement = (id, brand_id) => {
        let price = 0
        setCarts(data =>
            data.map(item => {
                if (item.brand.id === brand_id) {
                    return {
                        ...item,
                        products: item.products.map(product => {
                            if (product.id === id) {
                                if (product.quantity < product.inventory_quantity || product.inventory_quantity === -1) {
                                    price = product.variation_attributes.price;
                                    updateCartQuantity(id, product.quantity + 1)
                                    return { ...product, quantity: product.quantity + 1, product_total: product.product_total + price };
                                } else {
                                    return product;
                                }
                            } else {
                                return product;
                            }
                        }),
                        total: item.total + price,
                    }
                } else {
                    return item
                }
            })
        );
        setSubTotal(subTotal + price);
    };
    const handleDecrement = (id, brand_id) => {
        let price = 0
        setCarts(data =>
            data.map(item => {
                if (item.brand.id === brand_id) {
                    return {
                        ...item,
                        products: item.products.map(product => {
                            if (product.id === id) {
                                if (product.quantity > 1) {
                                    price = product.variation_attributes.price;
                                    updateCartQuantity(id, product.quantity - 1)
                                    return { ...product, quantity: product.quantity - 1, product_total: product.product_total - price };
                                }
                                else {
                                    return product;
                                }
                            } else {
                                return product;
                            }
                        }),
                        total: item.total - price,
                    }
                }
                else {
                    return item
                }
            })
        );
        setSubTotal(subTotal - price);
    };
    const handelUpdateShiping = (brand_id, new_shiping, old_shiping) => {
        setCarts(data =>
            data.map(item => {
                if (item.brand.id === brand_id) {
                    return {
                        ...item,
                        total:  new_shiping 
                    }
                }
                else {
                    return item
                }
            })
        );
    }
    const updateCartQuantity = (cartItemId, quantity) => {
        axios.put(`${baseURL}/cart/update/${cartItemId}`, {
            quantity: quantity
        }, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => {

            })
            .catch((error) => {

            });

    };

    const deleteCartItem = (cartItemId, product_total, brand_id) => {
        if (token) {
            axios.delete(`${baseURL}/cart/${cartItemId}`, { headers: { 'Authorization': `Bearer ${token}` } })
                .then((response) => {
                    const updatedCarts = carts.map((cart) => ({
                        ...cart,
                        products: cart.products.filter((product) => product.id !== cartItemId),
                    })).filter(cart => cart.products.length > 0);

                    setCarts(updatedCarts);
                    setSubTotal(subTotal - product_total);
                    setCartsNumber(response.data.count);
                    console.log(cartsNumber);
                })
                .catch((error) => {

                });
        }
        else {
            axios.delete(`${baseURL}/cart/${cartItemId}`, { params: { session_id: sesId } })
                .then((response) => {
                    const updatedCarts = carts.map((cart) => ({
                        ...cart,
                        products: cart.products.filter((product) => product.id !== cartItemId),
                    })).filter(cart => cart.products.length > 0);

                    setCarts(updatedCarts);
                    setSubTotal(subTotal - product_total);
                    setCartsNumber(response.data.count);
                    console.log(cartsNumber);
                })
                .catch((error) => {

                });
        }
    };

    //////// loading icon//////




    const getNewCart = (country, state) => {
        if (token) {
            axios.get(`${baseURL}/orders/checkout`, {
                headers: { 'Authorization': `Bearer ${token}` },
                params: {
                    country: country,
                    state: state
                }
            })
                .then((response) => {

                    setSubTotal(response.data.total_amount);
                    setCarts(response.data.data);

                })
                .catch((error) => {

                });
        }
        else {
            axios.get(`${baseURL}/orders/checkout`, {
                params: {
                    country: country,
                    state: state,
                    session_id: sesId
                }
            })
                .then((response) => {

                    setSubTotal(response.data.total_amount);
                    setCarts(response.data.data);

                })
                .catch((error) => {

                });
        }
    }




    return (
        <CartContext.Provider value={{ carts, setCarts, getCart, getNewCart, subTotal, handleIncrement, handleDecrement, updateCartQuantity, deleteCartItem, cartLength, handelUpdateShiping, cartNo, sesId, loadIcon, setLoadIcon, cartsNumber }}>
            {children}
        </CartContext.Provider>
    );
};
