import React, { useContext, useEffect, useState } from 'react'
import '../css/orders.scss'
import Grey_nav from './grey_Nav'
import { Link, useNavigate } from 'react-router-dom';
import '../css/responsive/orders_res.scss'
import axios from 'axios';
import { UserContext } from '../context/userContext';
import { Paginator } from 'primereact/paginator';


export default function Orders() {
    const [showOrders, setShowOrders] = useState(false);
    const [orders, setOrders] = useState(null);
    const [ordercount, setOrdercount] = useState(null);
    const navigator = useNavigate()


    let { baseURL, token } = useContext(UserContext);
    //------------------------ for pagination -------------------------------------------
    const [pagination, setPagination] = useState({ first: 0, from: 0, to: 0, totalRecords: 0, rows: 12 })
    useEffect(() => {
        fetchOrders(pagination.first / pagination.rows + 1);
    }, [pagination.first, pagination.rows]);

    const onPageChange = (e) => {
        setPagination({
            ...pagination,
            first: e.first,
            rows: e.rows
        })
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {

        const prevButton = document.querySelector('.p-paginator-prev');
        const nextButton = document.querySelector('.p-paginator-next');
        
        if (prevButton) {
            prevButton.addEventListener('click', handleScrollToTop);
        }

        if (nextButton) {
            nextButton.addEventListener('click', handleScrollToTop);
        }


    }, [pagination]);
    const handleScrollToTop = () => {
       
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 0); 
    };
    //----------------------  get orders (filter / pagination) -----------------
    const fetchOrders = (page) => {
        const queryParams = new URLSearchParams({ page: page }).toString();
        setOrders([]);
        axios.get(`${baseURL}/orders?${queryParams}`, { headers: { 'Authorization': `Bearer ${token}` } })
            .then(response => {
                const data = response.data.orders;
                setOrders(data.data);
                setPagination({
                    ...pagination,
                    totalRecords: data.total,
                    from: data.from,
                    to: data.to,
                    rows: data.per_page
                });
                setOrdercount(response.data.orders.total)
            })
            .catch()
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
      
        // Extract the month, day, and year
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based, so add 1
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
      
        return `${month}/${day}/${year}`;
    };

    // ---------------------------------------------------------------------------------------------------------------------
    return (
        <div className='orders'>
            <Grey_nav />

            <div className="orders-wrapper">
                <div className="orders-flower-img">
                    <img src="./assests/recover-flower.svg" alt="" />
                </div>

                <div className="orders-orders">ORDERS
                    {
                        ordercount > 0 ? (
                            <div className="fav-abs-no">{ordercount}</div>
                        ) : (
                            <div className="fav-abs-no">0</div>
                        )
                    }

                </div>
                {
                    orders && orders.length > 0 ? (
                        <>
                            <table className='orders-table'>
                                <thead>
                                    <tr>
                                        <th className='orders-th'>ORDER</th>
                                        <th className='orders-th text-center'>No. Items</th>
                                        <th className='orders-th text-center'>TOTAL PRICE</th>
                                        <th className='orders-th text-center'>ORDER NUMBER</th>
                                        <th className='orders-th text-center'>ORDER DATE</th>
                                        <th className='orders-th text-center'>ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        orders.map(order => (
                                            <tr className='orders-tr' key={order.id}>
                                                <td className=' orders-td'>
                                                    <img className='orders-img' src={order.image.image} alt="" />
                                                    <div className=" orders-golden-color mx-2">
                                                        {/* <div className="orders-golden">{order.name}</div>
                                                        {order.variation && <div className="orders-color">{order.variation.name} : {order.variation.value}</div>}
                                                        {order.variation2 && <div className="orders-color">{order.variation2.name} : {order.variation2.value}</div>}
                                                        {order.variation3 && <div className="orders-color">{order.variation3.name} : {order.variation3.value}</div>} */}
                                                    </div>
                                                </td>
                                                <td className='text-center'>{order.total_quantity}</td>
                                                <td className='text-center'>{order.total_price}</td>
                                                <td className='text-center'>{order.id}</td>
                                                <td className='text-center'>{formatDate(order.order_date)}</td>
                                                <td className='text-center'> {order.refunded ==0 && <Link to={`/return_request/${order.id}`}><img className='orders-img' src="./assests/orders-request.svg" alt="" /></Link>}</td>

                                            </tr>
                                        ))
                                    }
                                </tbody>

                            </table>
                            <div className="orders-table-res">
                                <div className="text-center my-4">Here your orders</div>
                                {orders.map((order, index) => (
                                        <>
                                            <div className="orders-order-res">
                                                <div className="orders-img-details">
                                                    <img className='orders-img-product' src={order.image.image} alt="" />
                                                    <div className="d-flex flex-column">
                                                        <div className="orders-details">{order.name}</div>
                                                        <div className="orders-details2">No. Items: {order.total_quantity}</div>
                                                        <div className="orders-details2">Price : ${order.total_price}</div>
                                                        <div className="orders-details2">Order Number : {order.order_number}
                                                            
                                                        </div>
                                                      

                                                        <div className="orders-details2">Order Date : {order.order_date}</div>

                                                    </div>
                                                </div>
                                                {order.refunded ==0 && <Link to={`/return_request/${order.id}`} className='orders-order-return-res'>

                                                    <img className='orders-img-res' src="./assests/return.svg" alt="" />
                                                    <div className="orders-request-res">RETURN REQUEST</div>
                                                </Link>}
                                            </div>
                                            <div className="orders-line2"></div>
                                        </>
                                    ))
                                }
                            </div>
                            <Paginator first={pagination.first} rows={pagination.rows} totalRecords={pagination.totalRecords} onPageChange={onPageChange} />

                        </>
                    ) : (
                        <>
                            <div className="orders-you">You have not placed any order yet.</div>
                            <div className={"orders-start " + (showOrders && "orders-start-none")} onClick={() => { navigator('/products') }}>START SHOPPING</div>
                        </>
                    )
                }
            </div>
        </div>
    )
}
