import React, { useEffect, useState, useContext } from 'react'
import { UserContext } from '../../context/userContext';
import axios from 'axios';
import { CartContext } from '../../context/cartContext';
import { useNavigate } from 'react-router-dom';

export default function CartProduct({ handleCloseCart }) {
    const handleImageError = (e) => {
        e.target.src = defaultImage;
    };
    const navigate = useNavigate();

    const { carts, handleIncrement, handleDecrement, deleteCartItem } = useContext(CartContext);
    const { defaultImage } = useContext(UserContext)


    return (
        <div className='checkout-cart-products'>

            {carts.map((cartItem, index) => (
                <div className='checkout-cart-item' key={index + 'item'}>{
                    cartItem.products.map((product => (
                        <div key={product.id} >
                            <div className="d-flex w-100 mt-3">
                                <div className="checkout-menu me-4">
                                    <div className="checkout-swiper-img-1" onClick={() => {
                                        navigate(`/details/${product.product_id}`);
                                        handleCloseCart && handleCloseCart();
                                    }}>
                                        <img src={product.images && product.images.length > 0 ? product.images[0].image : defaultImage}
                                            alt={product.name} onError={handleImageError} />
                                    </div>
                                </div>
                                <div className="d-flex flex-column w-100">
                                    <div className="checkout-rebel">{cartItem.brand.name}</div>
                                    <div className=" checkout-golden-golden">
                                        <div className="checkout-golden" id='checkout-golden2' >{product.product_name}</div>

                                        <div className="checkout-golden" id='checkout-golden'> {product.out_of_stock === 1 ? (
                                            <div className='error-msg2'>Sold Out</div>
                                        ) : (
                                            `$${parseFloat(product.product_total).toFixed(2)}`
                                        )}
                                        </div>
                                    </div>
                                    {product.variation_attributes.variation && (
                                        <div className="checkout-color">{product.variation_attributes.variation} : {product.variation_attributes.variation_value}</div>
                                    )}
                                    {product.variation_attributes.variation2 && (
                                        <div className="checkout-color">{product.variation_attributes.variation2} : {product.variation_attributes.vartion_value2}</div>
                                    )}
                                    {product.variation_attributes.variation3 && (
                                        <div className="checkout-color">{product.variation_attributes.variation3} : {product.variation_attributes.vartion_value3}</div>
                                    )}
                                    {/* {product.variation_attributes.size &&(
                            <div className="checkout-color">Size : {product.variation_attributes.size}</div>
                        )} */}
                                    <div className="d-flex mt-3 align-items-center">
                                        <div className="detailsModal-plus-minus d-flex">
                                            <div className="details-plus5" onClick={() => handleDecrement(product.id, cartItem.brand.id)}>-</div>
                                            <div className="checkout-product-quan">{product.quantity}</div>
                                            <div className="details-plus5" onClick={() => handleIncrement(product.id, cartItem.brand.id)}>+</div>
                                        </div>
                                        <div className="detailsModal-remove" onClick={() => deleteCartItem(product.id, product.product_total)}>Remove</div>
                                    </div>
                                </div>

                            </div>
                            <div className="detailsModal-line2">
                            </div>
                        </div>
                    )
                    ))
                }

                </div>
            ))}
        </div>
    )
}
