import React, { useState } from 'react'
import '../css/privacy.scss'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { CiCirclePlus, CiCircleMinus } from "react-icons/ci";
import '../css/FAQ.scss'
import '../css/responsive/shipping_res.scss'

export default function Shipping() {
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded({ ...expanded, [panel]: isExpanded });
    };
    return (
        <div className='privacy'>
            {/* <div className="faq-flower-img">
                <img src="./assests/faq-flower.svg" alt="" />
            </div> */}
            <div className="recover-wrapper">
                <div className="privacy-privacy">SHIPPING DETAILS</div>
                {/* <div className="faq-contact">
                    Frequently asked questions about jewelry
                </div> */}
                <div className="privacy-the2">
                    We believe that an exceptional customer experience is more than convenience and selection - it is an assurance of quality, authenticity, and intention. <br /><br /> All orders are fulfilled by our partnered designers with careful consideration for the item ordered.
                </div>
                <Accordion onChange={handleAccordionChange('panel1')} expanded={expanded['panel1']}>
                    <AccordionSummary
                        expandIcon={expanded['panel1'] ? <CiCircleMinus className='FAQ-accordion-plus' /> : <CiCirclePlus className='FAQ-accordion-plus' />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <div className="privacy-contact3">
                            How do I track my order?
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="privacy-the3">
                            Once you order has been shipped, you will receive an email with the tracking number of your package from the partnered designer.
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion onChange={handleAccordionChange('panel2')} expanded={expanded['panel2']}>
                    <AccordionSummary
                        expandIcon={expanded['panel2'] ? <CiCircleMinus className='FAQ-accordion-plus' /> : <CiCirclePlus className='FAQ-accordion-plus' />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <div className="privacy-contact3">
                            How long will it take my order to arrive?
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="privacy-the3">
                            On Shortlisted many of our items are meticulously made to order. Specific item fabrication time will be available in the item description where applicable.
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion onChange={handleAccordionChange('panel3')} expanded={expanded['panel3']}>
                    <AccordionSummary
                        expandIcon={expanded['panel3'] ? <CiCircleMinus className='FAQ-accordion-plus' /> : <CiCirclePlus className='FAQ-accordion-plus' />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <div className="privacy-contact3">
                        How Much Does Shipping Cost?
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="privacy-the3">
                        Shortlisted offers free standard shipping in the continental US on most items. 
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion onChange={handleAccordionChange('panel4')} expanded={expanded['panel4']}>
                    <AccordionSummary
                        expandIcon={expanded['panel4'] ? <CiCircleMinus className='FAQ-accordion-plus' /> : <CiCirclePlus className='FAQ-accordion-plus' />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <div className="privacy-contact3">
                        I've entered the incorrect shipping address, how do I change it?
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="privacy-the3">
                        Please contact us as soon as possible with your order number, the incorrect address entered, and the corrected address. Shortlisted will make every attempt to correct the address with our partner designers
                        </div>
                    </AccordionDetails>
                </Accordion>
               
            </div>
        </div>
    )
}
