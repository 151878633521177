import React, { useEffect, useState } from 'react'
import {  Modal } from 'react-bootstrap';
import { IoCloseOutline } from "react-icons/io5";
import { Dropdown } from 'primereact/dropdown';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import USStates from '../../constants/USStates';


export default function AddressEditModal({ show, handleClose,errors , address,UpdateAdress}) {
    const [editForm, setEditForm] = useState({})

    useEffect(() => {
        setEditForm(address);
    }, [address]);

   
// ---------------------  update the form ------------------------
    function HandleChangeForm (e){
        const {name,value}=e.target;
        setEditForm({
            ...editForm,
            [name]:value
        })
    }
    // ---------------------------------------------------------------------------
    return (

        <div>
            <Modal show={show} onHide={handleClose} className="custom-modal custom-mod">

                <Modal.Body>
                    <div className=" addressModal-add-close">
                        <div className="addressModal-add">Add a new address</div>
                        <IoCloseOutline className='modal-close' onClick={handleClose} />
                    </div>
                    <div className="addressModal-body-cont">
                        <div className="addressModal-please">Please fill in the fields below.</div>

                        <div className="d-flex">
                            <input type="text" placeholder='First name' className='addressModal-input2' defaultValue={address.first_name} name="first_name" onChange={HandleChangeForm} />
                            <input type="text" placeholder='Last name' className='addressModal-input3' defaultValue={address.last_name} name="last_name" onChange={HandleChangeForm}/>
                        </div>
                        <div className="d-flex">
                            {errors.first_name && <div className='error'>{errors.first_name[0]}</div>}
                            {errors.last_name && <div className='error'>{errors.last_name[0]}</div>}
                        </div>

                        <input type="text" placeholder='Company (optional)' className='addressModal-input' defaultValue={address.company} name="company" onChange={HandleChangeForm}/>
                        {errors.company && <div className='error'>{errors.company[0]}</div>}

                        <input type="text" placeholder='Address' className='addressModal-input'defaultValue={address.address} name="address" onChange={HandleChangeForm}/>
                        {errors.address && <div className='error'>{errors.address[0]}</div>}

                        <input type="text" placeholder='Apartment, suite, etc. (optional)' className='addressModal-input' defaultValue={address.apartment} name="apartment" onChange={HandleChangeForm}/>
                        {errors.apartment && <div className='error'>{errors.apartment[0]}</div>}

                        <input type="text" placeholder='City' className='addressModal-input' name="city" defaultValue={address.city} onChange={HandleChangeForm} />
                        {errors.city && <div className='error'>{errors.city[0]}</div>}

                        <div className="d-flex  ">                          
                            <Dropdown value={editForm.state} onChange={(e) => setEditForm({...editForm,state:e.value}) } options={USStates} optionLabel="name"
                                placeholder="Select a state" className="addressModal-input2" checkmark={true} highlightOnSelect={false}  name='state'/>

                            <input type="text" placeholder='ZIP code' className='addressModal-input2' defaultValue={address.zipcode} name="zipcode" onChange={HandleChangeForm}/>
                        </div>
                        <div className="d-flex">
                            {errors.state && <div className='error'>{errors.state[0]}</div>}
                            {errors.zipcode && <div className='error'>{errors.zipcode[0]}</div>}
                            
                        </div>

                        <input type="text" placeholder='Phone ( optional)' className='addressModal-input'defaultValue={address.phone} name="phone" onChange={HandleChangeForm}/>
                        {errors.phone && <div className='error'>{errors.phone[0]}</div>}

                        <div className=" addressModal-check-save">
                            <input type="checkbox" checked={editForm.default} name="default"  onChange={(e) => setEditForm({ ...editForm, default: e.target.checked })} />
                            <div className="addressModal-set"> Set as default</div>
                        </div>
                        <div className="addressModal-save"  onClick={() => { UpdateAdress(editForm); }}> Save Changes</div>
                    </div>
                </Modal.Body>

            </Modal>

        </div>
    )
}
