import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../css/grey_nav.scss';
import axios from 'axios';
import { UserContext } from '../context/userContext';
import { useNavigate } from "react-router-dom";

export default function Grey_Nav() {
        const navigate = useNavigate();
    let { baseURL, token, logout} = useContext(UserContext)
    const location = useLocation();

    const HandleLogout=()=>{
        
        
        axios.post(`${baseURL}/logout`, {},{headers: {'Authorization': `Bearer ${token}`}})
        .then((response)=>{
            
            logout();
            window.location.reload(); 
            localStorage.clear();
            navigate("/login");
        })
        .catch((error)=>{})
    }

    return (
        <div className='grey_nav'>
            <div className="grey_nav-wrapper">
                <Link to="/orders" className={location.pathname === '/orders' ? 'grey_nav-active' : 'grey_nav-nav'}>Orders</Link>
                <div className="grey_nav-break"></div>
                <Link to="/address" className={location.pathname === '/address' ? 'grey_nav-active' : 'grey_nav-nav'}>Addresses</Link>
                {/* <div className="grey_nav-break"></div>
                <Link to="/payment" className={location.pathname === '/payment' ? 'grey_nav-active' : 'grey_nav-nav'}>Payment Methods</Link> */}
                <div className="grey_nav-break"></div>
                <Link to="/favourites" className={location.pathname === '/favourites' ? 'grey_nav-active' : 'grey_nav-nav'}>My Shortlist</Link>
                <div className="grey_nav-break" ></div>
                <div className="grey_nav-nav" onClick={HandleLogout}>Logout</div>
                
            </div>
        </div>
    );
}
