import React,{useState} from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { IoCloseOutline } from "react-icons/io5";

export default function PaymentModal({ show, handleClose ,handleAddCard }) {

    
    return (
        <div>
            <Modal show={show} onHide={handleClose} >

                <Modal.Body>
                    <IoCloseOutline className='modal-close' onClick={handleClose} />
                    <div className="paymentModal-body-cont">
                        <div className="paymentModal-add">Add new card</div>
                        <div className="paymentModal-please">Please fill in the fields below:</div>

                        <div className="paymentModal-cash-methods">
                            <img src="./assests/footer-visa.png" alt="" />
                            <img src="./assests/footer-master.png" alt="" />
                            <img src="./assests/footer-express.png" alt="" />
                            <img src="./assests/footer-pay.png" alt="" />
                            <img src="./assests/footer-pal.png" alt="" />
                            <img src="./assests/footer-discover.png" alt="" />
                            <img src="./assests/modal-1.png" alt="" />
                        </div>
                        <div className="paymentModal-card">Card number</div>
                        <div className="paymentModal-input-img">
                            <input type="text" name="" id="" className='paymentModal-input' placeholder='9445 0000 0000 0000' />
                            <img src="./assests/footer-master.png" alt="" className='paymentModal-master' />
                        </div>
                        <div className=" paymentModal-cards">
                            <div className="paymentModal-card-cont">
                                <div className="paymentModal-card">Expiry date</div>
                                <input type="text" name="" id="" className='paymentModal-input2' placeholder='MM/YY' />
                            </div>
                            <div className="">
                                <div className="paymentModal-card">CVV</div>
                                <input type="text" name="" id="" className='paymentModal-input2' placeholder='000' />
                            </div>
                        </div>
                        <div className="paymentModal-card">Cardholder name</div>
                        <input type="text" name="" id="" className='paymentModal-input2' placeholder='Enter cardholder full name' />
                        <div className="paymentModal-check-card">
                            <input type="checkbox" className='paymentModal-check' />
                            <div className="paymentModal-card">Save card as default</div>
                        </div>
                        <div className="paymentModal-cancel-add">
                            <div className="paymentModal-cancel" onClick={handleClose}>Cancel</div>
                            <div className="paymentModal-add2" onClick={() => { handleAddCard(); handleClose(); }}>Add Card</div>
                        </div>

                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}
