import React, { useState, useEffect, useContext  } from "react";
import '../../css/shop.scss'
import '../../css/responsive/shop_res.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/autoplay';
import ShopFilter from "./shopFilter";
import ShopProduct from "./shopProduct";
import { UserContext } from "../../context/userContext";
import axios from "axios";
import { Paginator } from 'primereact/paginator';
import { useLocation, useNavigate, useParams } from "react-router-dom";


export default function ProductsCollection() {
    const [isWindowClicked, setIsWindowClicked] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [checkedItems, setCheckedItems] = useState([]);
    const [noProduct, setNoProduct] = useState(false);
    const [exclusive, setExclusive] = useState(false);
    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex);
    };
    const handleCheckboxChange = (itemName) => {
        const isChecked = checkedItems.includes(itemName);
        if (isChecked) {
            setCheckedItems(checkedItems.filter(item => item !== itemName));
        } else {
            setCheckedItems([...checkedItems, itemName]);
        }
    };

// -----------------------------------------------------------------------------------------------------------
const { id } = useParams();
const location = useLocation();
let { baseURL,token,isfromBrandHeader,updatePage,setUpdatePage,pag,clearPagination} = useContext(UserContext);
const [hasMounted, setHasMounted] = useState(false);
const [products, setProducts] = useState([])
const [fixedSlider, setFixedSlider] = useState(null)
const [filter, setFilter] = useState({categories:'', brands:'', colors:'', sizes:'', min:0, max:0,})
const [filterObj, setFilterObj] = useState({collection_id:id})
const [settings, setSettings] = useState(null);
const navigate =useNavigate()
    
//--------------------- toggle filter section ----------------------------------------------
const isMobileView = () => window.innerWidth <= 662 ;
const [isFilterVisible, setIsFilterVisible] = useState(!isMobileView());
const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
};

//-----------------  change id get categories and brands and products  -----------------
 useEffect(() => {
    if (hasMounted) {
        setNoProduct(false)
        setUpdatePage(true);
        setFilterObj({collection_id:id})
        setSettings({...settings,name:location.state.name})
    }

   }, [id]);

    //--------------------- Update products after toggle favourites ----------------------------------------------
    const handelUpdateProducts=(new_products)=>{
        setProducts(new_products)
    }
    //--------------------- Update filter object  ----------------------------------------------
    const [updatePrice, setUpdatePrice] = useState(false);
    const handleFilterObj=(name,value)=>{
        if(name==='shortlisted_flag'){
            setUpdatePrice(true);
        }
        if(name==='clear'){
            setFilterObj({...filterObj, colors:'', from:'', to:'', category:[], sort:''})
        }
        else if(name==='priceRange'){
            setFilterObj({ ...filterObj,from:value[0],to:value[1],})
        }else{
            setFilterObj({...filterObj,[name]:value})
        }
    }
    //------------------------ for pagination -------------------------------------------
    const [pagination, setPagination] = useState( {...pag} );
    useEffect(() => {
        clearPagination();
    }, []);
    useEffect(() => {
        if (hasMounted) {
          fetchProducts(pagination.first / pagination.rows + 1);
        } 
      }, [pagination.first, pagination.rows]);

      useEffect(() => {
        if (hasMounted) {
          fetchProducts(1);
        } 
      }, [filterObj]);

    const onPageChange = (e) => {
        setPagination({...pagination,first:e.first,rows:e.rows})
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
        }, 1000);
    };
    useEffect(() => {

        const prevButton = document.querySelector('.p-paginator-prev');
        const nextButton = document.querySelector('.p-paginator-next');
        
        if (prevButton) {
            prevButton.addEventListener('click', handleScrollToTop);
        }

        if (nextButton) {
            nextButton.addEventListener('click', handleScrollToTop);
        }


    }, [pagination]);
    const handleScrollToTop = () => {
        
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
        }, 1000);
    };
    //-----------------  get categories and brands and products  -----------------
    useEffect(() => {
        setHasMounted(true);
        //-----------------  get brand imags and footer -----------------

       
        axios.get(`${baseURL}/collections/images`)
        .then((response)=>{
            setSettings((prevSettings) => ({
                ...prevSettings,
                images: response.data.data.images || [],
                footer: response.data.data.footer.url || ''
            }));
    })
        .catch()
        //-----------------  get categories -----------------
        axios.get(`${baseURL}/categories`,{headers: {'Authorization': `Bearer ${token}`}})
        .then((response)=>setFilter(prev=>({...prev,categories:response.data.data})))
        .catch() 
        //-----------------  get products -----------------
        const page = pag ? Math.floor(pag.from / pag.rows) + 1 : 1; 
        const from = pag?.from || 0;
        const rows = pag?.rows || 12;
        const queryParams = new URLSearchParams({
            page,
            ...filterObj
        }).toString();
        setProducts([]);
        axios.get(`${baseURL}/products?${queryParams}`, {headers: { 'Authorization': `Bearer ${token}` }})
        .then(response=>{
            const data = response.data.data;
           const coll_name= response.data.collection_name;
           setSettings((prevSettings) => ({
            ...prevSettings,
            name: location.state ? location.state.name : coll_name
          }));
            if(data.data.length <= 0){
                setNoProduct(true)
            }else{
                setNoProduct(false)
            }
            setProducts(data.data);
            setPagination({
                ...pagination,
                totalRecords: data.total,
                from: data.from,
                to: data.to,
                rows: data.per_page
            });
            setFilter(prev => ({
                ...prev ,
                 colors: response.data.colors,
                 sizes: response.data.sizes,
                 min: response.data.min_price,
                 max: response.data.max_price,
                }))
            setFixedSlider({min:response.data.min_price,max:response.data.max_price})
        })
        .catch()
        setSettings((prevSettings) => ({
            ...prevSettings,
            name: location.state ? location.state.name : ''
          }));
    }, [])
    
    //----------------------  get products (filter / pagination) -----------------
    const fetchProducts =(page) => {
        const queryParams = new URLSearchParams({
            page: page,
            ...filterObj 
        }).toString();
        setProducts([]);
        axios.get(`${baseURL}/products?${queryParams}`, {headers: { 'Authorization': `Bearer ${token}` }})
        .then(response=>{
            const data = response.data.data;
            if(data.data.length <= 0){
                setNoProduct(true)
            }else{
                setNoProduct(false)
            }

            setProducts(data.data);
            setPagination({
                ...pagination,
                totalRecords: data.total,
                from: data.from,
                to: data.to,
                rows: data.per_page
            });
            setFilter(prev => ({
                ...prev ,
                 colors: response.data.colors,
                 sizes: response.data.sizes,
                 min: response.data.min_price,
                 max: response.data.max_price,
                }))
                if(updatePrice){
                    setFixedSlider({min:response.data.min_price,max:response.data.max_price})
                    setUpdatePrice(false);
                }
        })
        .catch()
    };
  
    const hasImages = settings?.images && settings.images.length > 0;
    // ===================================== Sort in Responsive =============================================================================
    const [currnetSort, setCurrnetSort] = useState('asc')
    const toggleSort=()=>{
       let new_sort_value= currnetSort=== 'asc' ? 'desc' : 'asc'
       setCurrnetSort(new_sort_value);
       handleFilterObj('sort', new_sort_value);
    }
    {/* ------------------------------------------------------------------------------------------------ */}
    return (
        <div className='address'>
            {/* ------------------------ for brand title ----------------------------------------- */}
                <div className='grey_nav grey_nav2'>
                    <div className="grey_nav3">
                        <div className="grey_nav-wrapper grey_nav-wrapper2">
                            <div className={`grey_nav-nav`} onClick={()=>{navigate('/collections')}} >Shortlisted Stories</div>
                            <div className="grey_nav-break "></div>
                            <div className={`grey_nav-nav special-title `} >{settings?.name}</div>
                        </div>
                    </div>
                </div>
            <div className="shop-wrapper">
                <div className={`shop-wrapper-left-right ${isFilterVisible ? 'filter-visible' : ''}`}>
                    { (<div className="shop-wrapper-left">
                        <ShopFilter toggleFilterVisibility={toggleFilterVisibility} filter={filter} fixedSlider={fixedSlider} checkedItems={checkedItems} onCheckboxChange={handleCheckboxChange}  handleFilterObj={handleFilterObj} isfromBrandHeader={isfromBrandHeader} exclusive={exclusive} setExclusive={setExclusive} updatePage={updatePage} setUpdatePage={setUpdatePage}/>
                    </div>)}
                    <div  className="shop-wrapper-right">
                        <div className="silder-container">
                            {settings?.images && <Swiper 
                                className="shop"
                                spaceBetween={25}
                                slidesPerView={1}
                                navigation={{
                                    nextEl: ".home-right",
                                    prevEl: ".home-left",
                                }}
                                modules={[Navigation, Autoplay, Pagination]}
                               
                                // autoplay={{ delay: 2000 }}
                                speed={2000}
                                pagination={{
                                    clickable: true,
                                    bulletClass: "swiper-pagination-bullet",
                                    bulletActiveClass: "swiper-pagination-bullet-active",

                                }}
                                onSlideChange={handleSlideChange}
                                activeindex={activeIndex}
                            >
                                {settings?.images.map(slider=>(<SwiperSlide key={slider.id}>
                                    <img src={slider.url} className="shop-main-img" alt="" />
                                </SwiperSlide>))}
                            </Swiper >}
                            {/* <div className={`filter-icon ${hasImages ? '' : 'no-images'}`}>
                            <p className="filter-filter">Filter</p>
                                <img src={`${process.env.PUBLIC_URL}/assests/filter-icon.png`} onClick={toggleFilterVisibility} alt="" />
                            </div> */}
                             {products.length>0 && <div className={`filter-icon2 ${hasImages ? '' : 'd-none'} filter-res`}>
                                <div className="filter-res ">
                                    <div className="mx-3 d-flex align-items-center" onClick={toggleFilterVisibility}>
                                        <div>
                                            <img src={`${process.env.PUBLIC_URL}/assests/filter_icon_new.svg`}  alt="" /> 
                                        </div>
                                        <div>Filter</div>
                                    </div>
                                    <div className="mx-3 seprator_res"></div>
                                    <div className="mx-3 d-flex align-items-center" onClick={toggleSort}>
                                        <div>
                                            <img src={`${process.env.PUBLIC_URL}/assests/sort_icon_new.svg`}  alt="" /> 
                                        </div>
                                        <div>Sort By</div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="shop-necklace" >{settings?.name}</div>
                        {products.length>0 && <div className={`filter-icon2 ${hasImages ? 'd-none' : ''} filter-res`}>
                                <div className="filter-res ">
                                    <div className="mx-3 d-flex align-items-center" onClick={toggleFilterVisibility}>
                                        <div>
                                            <img src={`${process.env.PUBLIC_URL}/assests/filter_icon_new.svg`}  alt="" /> 
                                        </div>
                                        <div>Filter</div>
                                    </div>
                                    <div className="mx-3 seprator_res"></div>
                                    <div className="mx-3 d-flex align-items-center" onClick={toggleSort}>
                                        <div>
                                            <img src={`${process.env.PUBLIC_URL}/assests/sort_icon_new.svg`}  alt="" /> 
                                        </div>
                                        <div>Sort By</div>
                                    </div>
                                </div>
                            </div>}
                        {<ShopProduct products={products} pagination={pagination} handleFilterObj={handleFilterObj} isWindowClicked={isWindowClicked} setIsWindowClicked={setIsWindowClicked} handelUpdateProducts={handelUpdateProducts} title={settings?.name}/>}
                        {noProduct && <div className="no-item"> No Item Found </div>}
                        {products.length>0 && <div className="my-1">
                            <Paginator first={pagination.first} rows={pagination.rows} totalRecords={pagination.totalRecords} onPageChange={onPageChange} />
                        </div>}
                    </div>

                </div>
             { settings?.footer && <div className="shop-video">
                    <img src={settings.footer} alt="" className="shop-arrow w-100 h-100" />
                </div>}
            </div>
        </div>



    )
}
