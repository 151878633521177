const USStates = [
    { name: 'Alabama', code: 'AL', key: 'AL' },
    { name: 'Alaska', code: 'AK', key: 'AK' },
    { name: 'Arizona', code: 'AZ', key: 'AZ' },
    { name: 'Arkansas', code: 'AR', key: 'AR' },
    { name: 'California', code: 'CA', key: 'CA' },
    { name: 'Colorado', code: 'CO', key: 'CO' },
    { name: 'Connecticut', code: 'CT', key: 'CT' },
    { name: 'Delaware', code: 'DE', key: 'DE' },
    { name: 'Florida', code: 'FL', key: 'FL' },
    { name: 'Georgia', code: 'GA', key: 'GA' },
    { name: 'Hawaii', code: 'HI', key: 'HI' },
    { name: 'Idaho', code: 'ID', key: 'ID' },
    { name: 'Illinois', code: 'IL', key: 'IL' },
    { name: 'Indiana', code: 'IN', key: 'IN' },
    { name: 'Iowa', code: 'IA', key: 'IA' },
    { name: 'Kansas', code: 'KS', key: 'KS' },
    { name: 'Kentucky', code: 'KY', key: 'KY' },
    { name: 'Louisiana', code: 'LA', key: 'LA' },
    { name: 'Maine', code: 'ME', key: 'ME' },
    { name: 'Maryland', code: 'MD', key: 'MD' },
    { name: 'Massachusetts', code: 'MA', key: 'MA' },
    { name: 'Michigan', code: 'MI', key: 'MI' },
    { name: 'Minnesota', code: 'MN', key: 'MN' },
    { name: 'Mississippi', code: 'MS', key: 'MS' },
    { name: 'Missouri', code: 'MO', key: 'MO' },
    { name: 'Montana', code: 'MT', key: 'MT' },
    { name: 'Nebraska', code: 'NE', key: 'NE' },
    { name: 'Nevada', code: 'NV', key: 'NV' },
    { name: 'New Hampshire', code: 'NH', key: 'NH' },
    { name: 'New Jersey', code: 'NJ', key: 'NJ' },
    { name: 'New Mexico', code: 'NM', key: 'NM' },
    { name: 'New York', code: 'NY', key: 'NY' },
    { name: 'North Carolina', code: 'NC', key: 'NC' },
    { name: 'North Dakota', code: 'ND', key: 'ND' },
    { name: 'Ohio', code: 'OH', key: 'OH' },
    { name: 'Oklahoma', code: 'OK', key: 'OK' },
    { name: 'Oregon', code: 'OR', key: 'OR' },
    { name: 'Pennsylvania', code: 'PA', key: 'PA' },
    { name: 'Rhode Island', code: 'RI', key: 'RI' },
    { name: 'South Carolina', code: 'SC', key: 'SC' },
    { name: 'South Dakota', code: 'SD', key: 'SD' },
    { name: 'Tennessee', code: 'TN', key: 'TN' },
    { name: 'Texas', code: 'TX', key: 'TX' },
    { name: 'Utah', code: 'UT', key: 'UT' },
    { name: 'Vermont', code: 'VT', key: 'VT' },
    { name: 'Virginia', code: 'VA', key: 'VA' },
    { name: 'Washington', code: 'WA', key: 'WA' },
    { name: 'West Virginia', code: 'WV', key: 'WV' },
    { name: 'Wisconsin', code: 'WI', key: 'WI' },
    { name: 'Wyoming', code: 'WY', key: 'WY' }
];

export default USStates;
