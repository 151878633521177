import React, { useState, useEffect, useContext } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFlip, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/autoplay';
import { IoCloseOutline } from "react-icons/io5";
import { UserContext } from '../../context/userContext';
import axios from 'axios';
import { Link ,useNavigate} from 'react-router-dom';
export default function DetailsModal({ show, handleClose, brandModal }) {
    let { baseURL, token, updateFavLength } = useContext(UserContext);


    const [activeIndex, setActiveIndex] = useState(0);

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex);
    };


    const navigate = useNavigate();

    const handleSelectBrand = (flag, brand) => {
      
        navigate(`/products/designers/${brand}`)
       
    };


    return (
        <div>
            <Modal show={show} onHide={handleClose} size="xl">
                {/* <Modal.Header closeButton>
                    <Modal.Title>ADD SPACES</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <IoCloseOutline className='modal-close' onClick={handleClose} />
                    {/* <img src="./assests/close.png" alt="" className='modal-close' onClick={handleClose}/> */}
                    <div className="modal-body-cont">
                        <div className="modal-details-flex">
                            <div className="details-rebel">

                                {/* <img src={`${process.env.PUBLIC_URL}/assests/rebel.png`} alt="" /> */}
                                {brandModal.name}
                            </div>
                            <div className="modal-brought">
                                {/* Brought to life in 2017, Rebel Jewelry translates my story
                                into fine handcrafted pieces that can be worn, kept and
                                even passed down to our loved ones. The official launch
                                of Rebel Fine Jewelry was postponed for reasons beyond
                                my personal control. On the contrary, circumstances of
                                large magnitudes organically forced myself to make life
                                altering  decisions that would and did reshape not only
                                life in real time, but the overall trajectory I was on. */}
                                {brandModal.description}
                            </div>
                            <div  className="modal-explore" onClick={() => handleSelectBrand(true, brandModal.id)}>
                                Explore All
                                <div className="modal-right-arrow">
                                    <img src={`${process.env.PUBLIC_URL}/assests/arrow-right.png`} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-swiper">

                            <Swiper
                                className="mt-2 p-4 kite"
                                spaceBetween={25}
                                slidesPerView={1}
                                navigation={{
                                    nextEl: ".home-right",
                                    prevEl: ".home-left",
                                }}
                                modules={[Navigation, Autoplay, Pagination]}
                                
                                // autoplay={{ delay: 2000 }}
                                speed={2000}
                                pagination={{
                                    clickable: true,
                                    bulletClass: "swiper-pagination-bullet",
                                    bulletActiveClass: "swiper-pagination-bullet-active",

                                }}
                                onSlideChange={handleSlideChange}
                                activeindex={activeIndex}
                            >
                                {brandModal.popup_images && brandModal.popup_images.map((image, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="modal-kite-golden">
                                            <div className="modal-kite-img">

                                                <img src={image.url} alt="" />
                                            </div>
                                            <div className="modal-golden">
                                                {image.name}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                                {/* <SwiperSlide>
                                    <div className="modal-kite-golden">
                                        <div className="modal-kite-img">
                                            <img src={`${process.env.PUBLIC_URL}/assests/modal-kite2.png`} alt="" />
                                        </div>
                                        <div className="modal-golden">
                                            Golden phoenix - Rebel
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="modal-kite-golden">
                                        <div className="modal-kite-img">
                                            <img src={`${process.env.PUBLIC_URL}/assests/modal-kite2.png`} alt="" />
                                        </div>
                                        <div className="modal-golden">
                                            Golden phoenix - Rebel
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="modal-kite-golden">
                                        <div className="modal-kite-img">
                                            <img src={`${process.env.PUBLIC_URL}/assests/modal-kite2.png`} alt="" />
                                        </div>
                                        <div className="modal-golden">
                                            Golden phoenix - Rebel
                                        </div>
                                    </div>
                                </SwiperSlide> */}

                            </Swiper >
                        </div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
          
        </Modal.Footer> */}
            </Modal>

        </div>
    )
}
