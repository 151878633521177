import React, { useContext, useEffect, useState } from 'react'
import '../css/collections.scss'
import '../css/responsive/collections_res.scss'
import { UserContext } from '../context/userContext';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from 'react-router-dom';
export default function Collections() {

    let { baseURL, token , updateHeaderData } = useContext(UserContext);
    const [collections, setCollections] = useState('');
    const navigate=useNavigate()

  //------------------------ for pagination -------------------------------------------
  const [pagination, setPagination] = useState({first:0,from:0,to:0,totalRecords:0,rows:12})
  useEffect(() => {
          fetchCollections(pagination.first / pagination.rows + 1);
    }, [pagination.first, pagination.rows]);

  const onPageChange = (e) => {
      setPagination({
          ...pagination,
          first:e.first,
          rows:e.rows
      })
      window.scrollTo({top: 0, behavior: 'smooth'});
  };
  //----------------------------------- get collection---------------------------------------------------
  const fetchCollections=(page)=>{
    const queryParams = new URLSearchParams({page: page}).toString();
    setCollections('');
    axios.get(`${baseURL}/collections?${queryParams}`, {headers: { 'Authorization': `Bearer ${token}` }})
    .then((response) => {
      const data = response.data.data;
        setCollections(response.data.data.data);
        setPagination({
          ...pagination,
          totalRecords: data.total,
          from: data.from,
          to: data.to,
          rows: data.per_page
      });
    })
    .catch((error) => { })
};
  //----------------------------------- select collection---------------------------------------------------
    const handleSelectCollection=(id,name)=>{
      navigate(`/products/collection/${id}`, { state: { name } })
    }
    // updateHeaderData('collection',{id:id,name:name})
    // navigate('/shop')

  
  return (
    <div className='privacy'>
            <div className="designers-wrapper">
                <div className="privacy-privacy">Shortlists</div>
                <div className="collections-discover">
                Curated selections blending the latest trends with enduring classics to compliment your personal style.
                </div>
                <div className="designers-imgs">
                    {collections && collections.map(collection=>(
                        <div key={collection.name} onClick={()=>{handleSelectCollection(collection.id,collection.name)}} className="designer-img-text">
                        <div className="designers-img">
                            <img src={collection.image} alt="" />
                        </div>
                        <div className="designers-rebel">{collection.name}</div>
                        <div className="designers-123">({collection.products})</div>
                    </div>))}
                </div>

            </div>
            {collections && <div className="my-1">
                            <Paginator first={pagination.first} rows={pagination.rows} totalRecords={pagination.totalRecords} rowsPerPageOptions={[12, 24, 36]} onPageChange={onPageChange} />
                        </div>}
        </div>
  )
}
