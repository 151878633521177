import React from 'react'

export default function Refund() {
    return (
        <div className='privacy'>
            {/* <div className="faq-flower-img">
                <img src="./assests/faq-flower.svg" alt="" />
            </div> */}
            <div className="recover-wrapper">
                <div className="privacy-privacy">REFUND POLICY</div>

                <div className="privacy-contact">
                    Payment
                </div>
                <div className="privacy-the2">
                    We currently accept payment in the form of credit card (American Express, Visa, Master Card, and Discover). Orders will be processed within 24-48 hours pending item availability and credit card approval. All purchases shipped within the state of Massachusetts will be charged sales tax.
                </div>
                <div className="privacy-contact">
                    RETURNS & EXCHANGES
                </div>
                <div className="privacy-the2">
                    If you wish to return or exchange an item, you must request a return within 10 days after receiving your order. We will not accept returns that fall outside this 10 day period and we ask that you ensure all items remain unused and are returned in their original package with tags attached. Please see each item’s description for detailed return information.
                </div>
               
                <div className="privacy-the2">
                    If you wish to make a return or exchange, please email Support@Shopshortlisted.com to receive a <span className='privacy-contact2'> Return Authorization code.</span> This code is required for us to accept any returns/exchanges and the customer may be responsible for all shipping fees incurred. Returns will be processed as soon as possible but please allow up to two billing cycles for the return to appear on your credit statement. Shortlisted has the right to deny any refund that does not meet our return and exchange policy requirements. Any fit or production issues will be addressed on a case-by-case basis.
                </div>
            </div>
        </div>
    )
}
