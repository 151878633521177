import React from 'react'
import './email.scss';
import { Link } from 'react-router-dom';
export default function EmailSecond() {
    return (
        <div className='email'>
            <div className="email-container">
                <img src="./assests/email-flower.png" alt="" className='email-flower' />

                <img src="./assests/email-logo.png" alt="" className='email-logo' />
                <img src="./assests/email-croc.png" alt="" className='email-croc' />
                <div className="email-inv-24">
                    <div className="email-inv-voice">

                        <div className="email-invoice">
                            INVOICE
                        </div>
                        <div className="email-inv">
                            INV - 02
                        </div>
                    </div>

                    <div className="email-24">
                        24 May 2023
                    </div>
                </div>
                <div className="email-sherif-pay">
                    <div className="email-sherif-sheraton">
                        <div className="email-sherif">
                            Sherif Gamal
                        </div>
                        <div className="email-sheraton">
                            sheraton, Cairo, Egypt
                        </div>
                    </div>

                    <div className="email-pay-boston">
                        <div className="email-payment">
                            Payment To
                        </div>
                        <div className="email-short">
                            Shortlisted
                        </div>
                        <div className="email-boston">
                            Boston/West Palm Beach
                        </div>
                    </div>
                </div>
                <table className='email-table'>
                    <thead>
                        <tr>
                            <th className='email-th email-th-item'>ITEM</th>
                            <th className='email-th email-th-qty'>QTY</th>
                            <th className='email-th email-th-amount'>AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Bracelets</td>
                            <td className='email-td-qty'>01</td>
                            <td className='email-td-amount'>$2000</td>
                        </tr>
                        <tr>
                            <td>Ring</td>
                            <td className='email-td-qty'>01</td>
                            <td className='email-td-amount'>$3000</td>
                        </tr>
                        <tr>
                            <td>Earings</td>
                            <td className='email-td-qty'>01</td>
                            <td className='email-td-amount'>$3500</td>
                        </tr>
                    </tbody>
                </table>
                <div className="email-sub-amount">
                    <div className="email-sub">
                        SUBTOTAL
                    </div>
                    <div className="email-amount">
                        $8500
                    </div>
                </div>

                <div className="email-line"></div>
                <div className="email-social">
                    <a href="https://www.instagram.com/shopshortlisted" target='_blank' rel='noreferrer'>
                        <img src="./assests/email-insta.png" alt="" className='email-insta' />
                    </a>
                    <a href="https://www.tiktok.com/@shopshortlisted" target='_blank' rel='noreferrer'>
                        <img src="./assests/email-tick.png" alt="" className='email-insta' />
                    </a>
                    <img src="./assests/email-face.png" alt="" className='email-insta' />
                </div>
                <div className="email-line"></div>
                <div className="email-copy">
                    © 2024 Shortlisted. All rights reserved.
                </div>
                <div className="email-you">
                    You are receiving this mail because you registered to join Shortlisted platform as a user. This also shows that you agree to our Terms of use and Privacy Policies. If you no longer want to receive mails from use, click the unsubscribe link below to unsubscribe.
                </div>
                <div className="d-flex align-items-center">
                    <Link to='/privacy' className="email-privacy">
                        Privacy policy
                    </Link>
                    <div className="email-circle"></div>
                    <Link to='/terms' className="email-privacy">
                        Terms of service
                    </Link>
                    <div className="email-circle"></div>
                    <div className="email-privacy">
                        Help center
                    </div>
                    <div className="email-circle"></div>
                    <div className="email-privacy">
                        Unsubscribe
                    </div>

                </div>

            </div>
        </div>
    )
}