import React, { useState, useEffect, useContext, useRef } from 'react'
import { Dropdown } from 'primereact/dropdown';
import '../css/responsive/return_request_res.scss'
import { UserContext } from '../context/userContext';
import axios from 'axios';
import { useParams } from 'react-router-dom';
export default function Return_request() {
    let { baseURL, token, updateFavLength, defaultImage } = useContext(UserContext);
    const { orderid } = useParams();

    const [userReturn, setUserReturn] = useState({
        name: "",
        phone: "",
        email: "",
        reason: '',
        image: ''
    });
    const handleReturn = () => {
        const formData = new FormData();
        formData.append('name', userReturn.name);
        formData.append('phone', userReturn.phone);
        formData.append('email', userReturn.email);
        formData.append('reason', userReturn.reason);
        formData.append('image', userReturn.image);

        axios.post(`${baseURL}/orders/refund/${orderid}`,
            formData, {
            headers: { 'Authorization': `Bearer ${token}` }
        })

            .then((response) => {

                
                setSuccess('item has been refunded successfully');
                setUserReturn({ name: '', phone: '', email: '',reason:'',image:'' });

                // Set timeout to clear success message after 3 seconds
                successTimeoutRef.current = setTimeout(() => {
                    setSuccess('');
                }, 3000);
            })
            .catch((error) => {

               
                if (error.response) {
                    if (error.response.status === 404) {
                       
                    } else {
                        
                    }
                } else if (error.request) {
                   
                } else {
                    
                }
            });
    };
    const [success, setSuccess] = useState('');
    const successTimeoutRef = useRef(null);
    useEffect(() => {
        return () => {
          clearTimeout(successTimeoutRef.current);
        };
      }, []);

    const [selectedReasons, setSelectedReasons] = useState(null);
    const reasons = [
        { name: 'The product arrived broken, malfunctioning, or with missing parts', code: 'NY' },
        { name: 'The product received does not match the description provided on the website', code: 'RM' },
        { name: 'The customer decides they no longer want the product', code: 'LDN' },

    ];
    const fileInputRef = useRef(null);
    const [fileInfo, setFileInfo] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileInfo({
                name: file.name,
                size: formatFileSize(file.size),
            });
            setUserReturn(prevState => ({
                ...prevState,
                image: file
            }));
            simulateUploadProgress();
        }
    };

    const formatFileSize = (size) => {
        if (size < 1024) return `${size} bytes`;
        if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
        if (size < 1024 * 1024 * 1024) return `${(size / (1024 * 1024)).toFixed(2)} MB`;
        return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    };

    const simulateUploadProgress = () => {
        let progress = 0;
        const interval = setInterval(() => {
            progress += 10;
            setUploadProgress(progress);
            if (progress >= 100) {
                clearInterval(interval);
                setTimeout(() => {
                    setUploadProgress(100);

                }, 1000); // Wait 1 second before hiding the progress bar
            }
        }, 200);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserReturn(prevState => ({
            ...prevState,
            [name]: value
        }));

    };
    const handleDropdownChange = (e) => {
        setSelectedReasons(e.value); 
        setUserReturn(prevState => ({
            ...prevState,
            reason: e.value.name
        }));
    };

    return (
        <div className='privacy'>

            <div className="contact-wrapper">

                <div className="privacy-privacy">
                    RETURN REQUEST
                    {/* <div className="contact-bird-img">
                        <img src="./assests/contact-bird.svg" alt="" />
                    </div> */}
                </div>
                <div className="contact-left-right">
                    <div className="contact-left">
                        <div className="contact-details">CONTACT DETAILS</div>
                        <div className="contact-grey-line"></div>

                        <img className='contact-logo' src={`${process.env.PUBLIC_URL}/assests/contact-logo.svg`} alt="" />
                        <div className="contact-boston">Boston/West Palm Beach</div>
                        <div className="contact-google">Google Maps</div>
                    </div>
                    <div className="contact-right">
                        <div className="return-rquest-contact-details">Contact details</div>
                        <div className="contact-details" id="return-contact-form">CONTACT FORM</div>
                        <div className="contact-grey-line2"></div>
                        <input type="text" className='contact-input' placeholder='Name*' value={userReturn.name}
                            onChange={handleChange} name="name" />
                        <input type="text" className='contact-input2' placeholder='Telephone*' value={userReturn.phone}
                            onChange={handleChange} name="phone" />

                        <input type="text" className='contact-input2' placeholder='Email*' value={userReturn.email}
                            onChange={handleChange} name="email" />
                        {/* <textarea name="" id="" className='contact-input2' placeholder='Message content *'></textarea> */}
                        <Dropdown value={selectedReasons} onChange={handleDropdownChange} options={reasons} optionLabel="name" placeholder="I want to return this product because …" className="w-full md:w-14rem return-request-dropdown" />

                        <div className="return-request-upload" onClick={handleClick}>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                            <img
                                className="return-request-img"
                                src={`${process.env.PUBLIC_URL}/assests/upload.svg`}
                                alt="Upload Icon"
                            />
                            {/* <div className="return-request-text">Drag and drop a file here or click</div> */}
                        </div>
                        <p className="return-request-file-name">File Upload</p>
                        {fileInfo && (
                            <>
                                <div className="return-request-file-info">
                                    <p className="return-request-file-name">{fileInfo.name}</p>
                                    <p className="return-request-file-name">{fileInfo.size}</p>

                                </div>
                                <div className="progress-bar">
                                    <div
                                        className="progress-bar-fill"
                                        style={{ width: `${uploadProgress}%` }}
                                    ></div>
                                </div>
                            </>
                        )}
                                {success && <div className="recover-success-message">{success}</div>}

                        <div className="contact-send" onClick={handleReturn}>Send</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
