import React from 'react'
import '../css/terms.scss'
import '../css/responsive/terms_res.scss'
export default function terms() {
    return (
        <div className='terms'>
            {/* <div className="faq-flower-img">
                <img src="./assests/faq-flower.svg" alt="" />
            </div> */}
            <div className="terms-wrapper">
                <div className="terms-privacy">TERMS OF SERVICE</div>
                <div className="terms-contact">
                    Overview
                </div>
                <div className="terms-the2">
                    This website is operated by Shortlisted. Throughout the site, the terms "we", "us" and "our" refer to Shortlisted. Shortlisted offers this website, including all information, tools and Services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
                </div>
                <div className="terms-the2">
                    By visiting our site and/ or purchasing something from us, you engage in our "Service" and agree to be bound by the following terms and conditions ("Terms of Service", "Terms"), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.
                </div>
                <div className="terms-the2">
                    Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any Services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
                </div>
                <div className="terms-the2">
                    Any new features or tools added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
                </div>

                <div className="terms-contact">
                    SECTION 1 - ONLINE STORE TERMS
                </div>
                <div className="terms-the2">
                    By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site. You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws). You must not transmit any worms or viruses or any code of a destructive nature. A breach or violation of any of the Terms will result in an immediate termination of your Services.
                </div>

                <div className="terms-contact">
                    SECTION 2 - GENERAL CONDITIONS
                </div>
                <div className="terms-the2">
                    We reserve the right to refuse Service to anyone for any reason at any time. You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the Service is provided, without express written permission by us. The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
                </div>


                {/* <div className="privacy-the2">
                    After reviewing this policy, if you have additional questions,
                    want more information about our privacy practices, or would
                    like to make a complaint, please contact us : justaregularwhitegirl@outlook.com
                </div>
                <div className="privacy-contact">
                    Collecting Personal Information
                </div>
                <div className="privacy-the2">
                    When you visit the Site, we collect certain information about your device, your interaction with the Site, and information necessary to process your purchases. We may also collect additional information if you contact us for customer support. In this Privacy Policy, we refer to any information about an identifiable individual (including the information below) as “Personal Information”. See the list below for more information about what Personal Information we collect and why.
                </div>
                <div className="privacy-contact2">
                    Device information :
                </div>
                <ul className="privacy-the2">
                    <li>  <span>Purpose of collection:</span> to load the Site accurately for you, and to perform analytics on Site</li>
                    <li> <span>Source of collection:</span> Collected automatically when you access our Site using cookies, log files, web beacons, tags, or pixels, etc.</li>
                    <li><span> Disclosure for a business purpose:</span> shared with our processor Shopify</li>
                    <li> <span> Personal Information collected:</span> version of web browser, IP address, time zone, cookie information, what sites or products you view, search terms, and how you interact with the Site.</li>
                </ul>
                <div className="privacy-contact2">
                    Order information :

                </div>
                <ul className="privacy-the2">
                    <li> <span> Order information : </span>urpose of collection: to provide products or services to you to fulfill our contract, to process your payment information, arrange for shipping, and provide you with invoices and/or order confirmations, communicate with you, screen our orders for potential risk or fraud, and when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</li>
                    <li> <span> Source of collection:</span> collected from you.</li>
                    <li> <span> Disclosure for a business purpose: </span>shared with our processor Shopify.</li>
                    <li> <span> Personal Information collected:</span> name, billing address, shipping address, payment information (including credit card numbers, email address, and phone number).</li>
                </ul>
                <div className="privacy-contact2">
                    Customer support information

                </div>
                <ul className="privacy-the2">
                    <li>   <span> Purpose of collection: </span>to provide customer support.</li>
                    <li>  <span> Source of collection: </span>collected from you</li>
                </ul>
                <div className="privacy-contact">
                    Minors
                </div>
                <div className="privacy-the2">
                    The Site is not intended for individuals under the age of 18. We do not intentionally collect Personal Information from children. If you are the parent or guardian and believe your child has provided us with Personal Information, please contact us at the address above to request deletion.                </div>
             */}
            </div>
        </div>
    )
}
