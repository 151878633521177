import React, { useState, useEffect,useContext } from 'react'
import '../../css/address.scss'
import Grey_nav from '../grey_Nav'
import AddressModal from '../modal/addressModal'
import { UserContext } from '../../context/userContext';
import axios from 'axios';
import AddressEditModal from '../modal/addressModalEdit';

import '../../css/responsive/address_res.scss'
export default function Address() {
    
let { baseURL,token } = useContext(UserContext);
const [addresses, setAddresses] = useState([])

const [showModal, setShowModal] = useState(false);
const handleOpenModal = () => setShowModal(true);
const handleCloseModal = () => setShowModal(false);

const [showEditModal, setShowEditModal] = useState(false);
const handleOpenEditModal = () => setShowEditModal(true);
const handleCloseEditModal = () => setShowEditModal(false);

const [errors, setErrors] = useState({})
const [editErrors, setEditErrors] = useState({})
const [currentAddress, setCurrentAddress] = useState({});

// ----------------------  add new addresses ------------------------
const handleAddCard = (form) => {
    handleCloseModal();
    form={
        ...form,
        state:JSON.stringify(form.state)
        }
        axios.post(`${baseURL}/addresses`,form,{headers: {'Authorization': `Bearer ${token}`}})
        
        .then(res=>{
          
            setAddresses(prevAddresses => [
                ...prevAddresses,
                res.data.data
            ]);
            
           
        }).catch(error=>{
            if (error.response.status == 400){
                setErrors(error.response.data.data)
            }
        });
    };
// ----------------------  get all addresses ---------------------
    useEffect(() => {
        axios.get(`${baseURL}/addresses`,{headers: {'Authorization': `Bearer ${token}`}})
        .then((response)=>{
            setAddresses(response.data.data)
        })
        .catch((error)=>{})
    }, [baseURL,token])

// ----------------------  delete addresses ---------------------
    const handleDeleteCard = (id) => {
        axios.delete(`${baseURL}/addresses/${id}`,{headers: {'Authorization': `Bearer ${token}`}})
        .then(res=>{
            setAddresses((prev)=>prev.filter(address=> address.id !== id))
        }).catch();

    };

// ---------------------- open edit model  ---------------------
    const handleOpenEditModel=(address)=>{
        const json_state=address.state
        const js_state = JSON.parse(json_state);
        address={...address,state:js_state}
        setCurrentAddress(address); 
        setShowEditModal(true);
    }
    
    // ---------------------- change default address  ---------------------
    const handleChangeDefault= (event,id)=>{
        const {checked } = event.target;
        const data = { default: checked ? 1 : 0 };

        axios.put(`${baseURL}/addresses/default/${id}`,data,{headers: {'Authorization': `Bearer ${token}`}})
        .then(res=>{setAddresses(res.data.data); })
        .catch();
    }

// ----------------------  edit addresses ---------------------
const UpdateAdress = (new_address) => {
    new_address = {
        ...new_address,
        state: JSON.stringify(new_address.state)
    };

    axios.put(`${baseURL}/addresses/${new_address.id}`, new_address, { headers: { 'Authorization': `Bearer ${token}` } })
        .then(res => {
            setAddresses((prev) => {
                if (new_address.default) {
                    return prev.map(addr => ({
                        ...addr,
                        default: addr.id === new_address.id ? 1 : 0
                    }));
                } else {
                    return prev.map(addr => addr.id === new_address.id ? new_address : addr);
                }
            });
            handleCloseEditModal();
        })
        .catch(error => {
            if (error.response.status === 400) {
                setEditErrors(error.response.data.data);
            }
        });
};

    
// ------------------------------------------------------------------------------
    return (
        <div className='address'><Grey_nav />
            <div className="address-wrapper">

                {/* <img src="./assests/address-flower.svg" alt="" className='address-flower'/> */}
                <div className="address-orders">ADDRESSES
                    <div className="fav-abs-no">{addresses.length??0}</div></div>
                <div className="address-you">You have not saved any addresses yet.</div>
                <div className=" address-container">
                    <div className="payment-location" id='address-location' onClick={handleOpenModal}>
                        <div className="address-img">
                            <img src="./assests/location.svg" alt="" />
                        </div>
                        <div className="address-add">Add a new address</div>
                    </div>
                    {addresses && addresses.map(address=>(
                        <div key={address.id} className="address-location2" id="address-location2">
                            <div className="address-boston">
                            {JSON.parse(address.state).name} / {address.address}
                            </div>
                            <div className="address-07">{address.first_name} {address.last_name}</div>
                            <div className="payment-07">{address.country}</div>
                            <div className="d-flex mt-auto">
                                <div className="payment-edit" onClick={()=>{handleOpenEditModel(address)}}>Edit</div>
                                <div className="payment-delete" onClick={()=>{handleDeleteCard(address.id)}}>Delete</div>
                                <input type="checkbox" name='default' checked={address.default} onClick={(event)=>{handleChangeDefault(event,address.id)}}/>
                                <div className="payment-default">Default</div>
                            </div>
                        </div>
                    ))}</div>
            </div>
            <AddressModal show={showModal} handleClose={handleCloseModal} handleAddCard={handleAddCard} errors={errors} />
            <AddressEditModal show={showEditModal} handleClose={handleCloseEditModal} UpdateAdress={UpdateAdress} address={currentAddress} errors={editErrors}  />
        </div>
    )
}
