import React from 'react'
import '../css/return.scss'
import '../css/responsive/return_res.scss'
import { Link } from 'react-router-dom'
export default function Return() {
    return (
        <div className='privacy'>
            {/* <div className="faq-flower-img">
                <img src="./assests/faq-flower.svg" alt="" />
            </div> */}
            <div className="recover-wrapper">
                <div className="privacy-privacy">RETURN POLICY</div>
                <div className="privacy-the">
                    Most items on our website are final sale. You may find the return policy for each product on the item’s description.
                    <br /><br />
                    If you wish to make a return please use the return request form on your account's order history or email support@Shopshortlisted.com to receive a Return Authorization. This authorization is required for us to accept any returns. the customer may be responsible for all shipping fees incurred. Returns will be processed as soon as possible, but please allow up to two billing cycles for the return to appear on your credit statement. Shortlisted has the right to deny any refund that does not meet our return and exchange policy requirements.
                </div>
                <div className="privacy-contact">
                    Have More Questions?
                </div>
                <Link to='/contact' className="return-contact-us">
                    CONTACT US
                    <div className="return-bird-img">
                        <img src="./assests/contact-bird.svg" alt="" />
                    </div>
                </Link>
            </div>
        </div>
    )
}
