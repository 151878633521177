import React from 'react'
import '../css/privacy.scss'
export default function privacy() {
    return (
        <div className='privacy'>
            {/* <div className="faq-flower-img">
                <img src="./assests/faq-flower.svg" alt="" />
            </div> */}
            <div className="recover-wrapper">
                <div className="privacy-privacy">PRIVACY POLICY</div>
                <div className="privacy-the">
                    This Privacy Policy describes how www.shopshortlisted.com (the “Site” or “we”) collects, uses, and discloses your Personal Information when you visit or make a purchase from the Site.
                </div>
                <div className="privacy-contact">
                    Contact
                </div>
                <div className="privacy-the2">
                    After reviewing this policy, if you have additional questions, want more information about our privacy practices, or would like to make a complaint, please contact us : support@shopshortlisted.com
                </div>
                <div className="privacy-contact">
                    Collecting Personal Information
                </div>
                <div className="privacy-the2">
                    When you visit the Site, we collect certain information about your device, your interaction with the Site, and information necessary to process your purchases. We may also collect additional information if you contact us for customer support. In this Privacy Policy, we refer to any information about an identifiable individual (including the information below) as “Personal Information”. See the list below for more information about what Personal Information we collect and why.
                </div>
                <div className="privacy-contact2">
                    Device information :
                </div>
                <ul className="privacy-the2">
                    <li>  <span>Purpose of collection:</span> to load the Site accurately for you, and to perform analytics on Site</li>
                    <li> <span>Source of collection:</span> Collected automatically when you access our Site using cookies, log files, web beacons, tags, or pixels, etc.</li>
                    <li><span> Disclosure for a business purpose:</span> shared with our processor Stripe</li>
                    <li> <span> Personal Information collected:</span> version of web browser, IP address, time zone, cookie information, what sites or products you view, search terms, and how you interact with the Site.</li>
                </ul>
                <div className="privacy-contact2">
                    Order information :

                </div>
                <ul className="privacy-the2">
                    <li> <span> Order information : </span>Purpose of collection: to provide products or services to you to fulfill our contract, to process your payment information, arrange for shipping, and provide you with invoices and/or order confirmations, communicate with you, screen our orders for potential risk or fraud, and when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</li>
                    <li> <span> Source of collection:</span> collected from you.</li>
                    <li> <span> Disclosure for a business purpose: </span>shared with our processor Stripe and out partner Designers.</li>
                    <li> <span> Personal Information collected:</span> name, billing address, shipping address, payment information (including credit card numbers, email address, and phone number).</li>
                </ul>
                <div className="privacy-contact2">
                    Customer support information

                </div>
                <ul className="privacy-the2">
                    <li>   <span> Purpose of collection: </span>to provide customer support.</li>
                    <li>  <span> Source of collection: </span>collected from you</li>
                </ul>
                <div className="privacy-contact">
                    Minors
                </div>
                <div className="privacy-the2">
                    The Site is not intended for individuals under the age of 18. We do not intentionally collect Personal Information from children. If you are the parent or guardian and believe your child has provided us with Personal Information, please contact us at the address above to request deletion.
                </div>
            </div>
        </div>
    )
}
