import React, { useEffect, useRef, useState } from 'react'
import { MdKeyboardArrowDown } from "react-icons/md";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { InputSwitch } from 'primereact/inputswitch';
import { Slider } from "primereact/slider";
import { IoCloseOutline } from "react-icons/io5";
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useLocation, useParams } from 'react-router-dom';

export default function ShopFilter({filter, fixedSlider , handleFilterObj ,exclusive, setExclusive , toggleFilterVisibility,updatePage,setUpdatePage,cateHeader}) {
    const [expanded, setExpanded] = useState({cates_accordion:true});
    const location= useLocation()
    useEffect(() => {
        if(fixedSlider){ 
            setValue([fixedSlider.min,fixedSlider.max])
        }
      }, [fixedSlider]);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded({ ...expanded, [panel]: isExpanded });
    };
    
    
    


    useEffect(() => {
        if(fixedSlider && updatePage){ 
            setValue([fixedSlider.min,fixedSlider.max])
            setSelectedCates('');
            setSelectedColor('');
            setSelectedSize('');
            setFilterSummery('');
            setUpdatePage(false)
        }
    }, [updatePage])

    useEffect(() => {
        if(cateHeader){
            const cateArray = cateHeader.split(',').map(Number);
              setFilterSummery({categories:cateArray});
            // alert('1')
            setSelectedCates(cateArray)
            
            //   const mockEvent = { target: {checked: true}};
            //   setSelectedCates(typeof(cateHeader))
            //   handleChangeCates(mockEvent,cateHeader)
        }
      }, [cateHeader])
    
    
{/* -------------------------------------------------------------------------------------------------------------- */}
    //------------------------- filter by brand -------------------------------
    const [selectedBrands, setSelectedBrands] = useState([])
    const handleChangeBrand = (event) => {
        const { name, checked } = event.target;
        setSelectedBrands((prevSelectedBrands) => {
            const updatedSelectedBrands = checked
            ? [...prevSelectedBrands, name]
            : prevSelectedBrands.filter((brand) => brand !== name);
            handleFilterObj('brand', updatedSelectedBrands);
            return updatedSelectedBrands;
        });
    
    };

    //------------------------- filter by cates -------------------------------
    const [selectedCates, setSelectedCates] = useState([])
    const handleChangeCates = (event,cate) => {
        const checked  = event.target.checked;
        const idAsInteger=parseInt(cate.id, 10);
        let sub_ids='';
        if(cate.children && cate.children.length){
            sub_ids = cate.children.map(child => child.id);
        }
        //------------ if cate has childern ------------
        if(sub_ids){
            let updatedSelectedCates =[]
            setSelectedCates((prev) => {
                if(checked){
                    updatedSelectedCates=[...prev,idAsInteger,...sub_ids]
                }
                else{
                    updatedSelectedCates = prev.filter((id) => !sub_ids.includes(id) && id !== idAsInteger);
                }
                handleFilterObj('category', updatedSelectedCates);
                setFilterSummery({...filterSummery,categories:updatedSelectedCates});
                return updatedSelectedCates;
            });
        }else{
            setSelectedCates((prevSelectedCates) => {
                const updatedSelectedCates = checked
                ? [...prevSelectedCates, idAsInteger]
                : prevSelectedCates.filter((cate) => cate !== idAsInteger);
                setFilterSummery({...filterSummery,categories:updatedSelectedCates});
                handleFilterObj('category', updatedSelectedCates);
                return updatedSelectedCates;
            });
        }   
    };
    //------------------------- filter by Color -------------------------------
    const [selectedColor, setSelectedColor] = useState('')
    const handleChangeColor=(color)=>{
        setSelectedColor(color.id);
        setFilterSummery({...filterSummery,colors:color.value})
        handleFilterObj('colors', color.id);

    }
    //------------------------- filter by size -------------------------------
    const [selectedSize, setSelectedSize] = useState('')
    const handleChangeSize=(size)=>{
        setSelectedSize(size.id)
        setFilterSummery({...filterSummery,sizes:size.value})
        handleFilterObj('sizes',size.id);
    }
    //------------------------- filter by shortlisted Exclusive -------------------------------
    const handleChangeExclusive= (e)=>{
        handleFilterObj('shortlisted_flag',e.value);
        setExclusive(e.value)
    }
    //------------------------- filter by price -------------------------------
    const [value, setValue] = useState([]);
    const debounceTimeoutRef = useRef(null);
    const handleSliderChange = (e) => { 
            setValue(e.value);
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }
            // Set a new debounce timer
            debounceTimeoutRef.current = setTimeout(() => {
                handleFilterObj('priceRange', e.value);
            }, 300); // 300ms debounce delay
        };



{/* -------------------------------------------------------------------------------------------------------------- */}
    //------------------------- filter summery -------------------------------
    const [filterSummery, setFilterSummery] = useState({
        categories:'',
        brands:'',
        colors:'',
        sizes:'',
    })
    //------------------------- Function to get category name by ID -------------------------------
    const getCategoryNameById = (id) => {
        const findCategory = (categories, id) => {
        for (let cate of categories) {
            if (cate.id === id) {
            return cate;
            } else if (cate.children && cate.children.length > 0) {
            const found = findCategory(cate.children, id);
            if (found) {
                return found;
            }
            }
        }
        return null;
        };
        const category = findCategory(filter.categories, id);
        return category ? category.name : '';
    };
    //------------------------- Function to Remove Color filter -------------------------------
    const removeColorFilter=()=>{
        setSelectedColor('');
        setFilterSummery({...filterSummery,colors:''})
        handleFilterObj('colors', '');
    }
    //------------------------- Function to Remove Size filter -------------------------------
    const removeSizeFilter=()=>{
        setSelectedSize('');
        setFilterSummery({...filterSummery,sizes:''})
        handleFilterObj('sizes', '');
    }
    //------------------------- Function to Remove cates filter -------------------------------
    const removeCatesFilter=(id)=>{
        const updatedSelectedCates = selectedCates.filter((cate) => cate !== id);
        setSelectedCates(updatedSelectedCates)
        setFilterSummery({...filterSummery,categories:updatedSelectedCates});
        handleFilterObj('category', updatedSelectedCates);
    }
    //------------------------- Function to Remove all filters -------------------------------
    const { id } = useParams();
    const removeAllFilters = () => {
        if(location.pathname.includes('/cate')){
            const cateArray = id.split(',').map(Number);
            setFilterSummery({categories:cateArray});
          setSelectedCates(cateArray)
        }else{
            setSelectedCates('');
            setFilterSummery('');
        }

        setSelectedColor('');
        setSelectedSize('');
        setValue([fixedSlider.min,fixedSlider.max]);
        handleFilterObj('clear', '');
    }
    //------------------------- Function to search brand -------------------------------
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
{/* -------------------------------------------------------------------------------------------------------------- */}
    return (
        <div>
            <div className="filter-header ">
                <div className="shop-filter">FILTER</div>
                <div className="img-close" onClick={toggleFilterVisibility}>
                     <img src={`${process.env.PUBLIC_URL}/assests/close.jpg`}  alt="" />
                </div>
            </div>
{/* ---------------------- filter Summery here ------------------------------------------------------------------- */}
            <div className="shop-filter-applied">
            
                {filterSummery.categories && filterSummery.categories.map(cate=> (
                    <div className="shop-one-filter"><IoCloseOutline className='shop-close' onClick={()=>{removeCatesFilter(cate)}} />{getCategoryNameById(cate)} </div>
                ))}
                {filterSummery.colors && (
                    <div className="shop-one-filter"><IoCloseOutline className='shop-close' onClick={removeColorFilter} />{filterSummery.colors} </div>
                )}
                {filterSummery.sizes && (
                    <div className="shop-one-filter"><IoCloseOutline className='shop-close' onClick={removeSizeFilter} />{filterSummery.sizes} </div>
                )}

               
            </div>
            {(selectedCates.length>0 || selectedColor || selectedSize) && (
                <div className="shop-clear-filter" onClick={removeAllFilters}>
                    <IoCloseOutline className='shop-close2' />
                    <div className="">CLEAR ALL FILTER</div>
                </div>
            )}
            { !location.pathname.includes('/exclusives') && <>
                <div className="d-flex align-items-center mb-3 justify-content-between">
                    <div className="shop-short ">Shortlisted Exclusives <img src={`${process.env.PUBLIC_URL}/assests/shop-star.png`} alt="" className='shop-star' /></div>
                    <InputSwitch checked={exclusive} onChange={handleChangeExclusive} />
                </div>
                <div className="shop-grey-line"></div>
            </>}

{/* ---------------------- Categories here ------------------------------------------------------------------- */}
            <div className="remove-border accordion-cates mb-3">
                <Accordion  onChange={handleAccordionChange('cates_accordion')} expanded={expanded['cates_accordion']}>
                    <AccordionSummary expandIcon={expanded['cates_accordion'] ? <MdKeyboardArrowDown className='shop-accordion-plus' /> : <MdKeyboardArrowDown className='shop-accordion-plus' />}>
                        <div className="shop-category">Categories</div>
                    </AccordionSummary>
                    <AccordionDetails>
                    {/* ----------------------- if cate has sub_categories----------------------------------- */}
                        {filter?.categories && filter?.categories.map(cate=>{
                            if(cate.children.length >0){
                                return (
                                    <Accordion key={cate.id} onChange={handleAccordionChange(`cate_${cate.id}`)} expanded={expanded[`cate_${cate.id}`]}>
                                        <div className="d-flex shop-cosmatics custom-checkbox">
                                            <input type="checkbox" className="me-2" id={cate.id} checked={selectedCates.includes(cate.id)} onChange={(event)=>{ handleChangeCates(event,cate);}} />
                                            <label htmlFor={cate.id}>{cate.name}</label>
                                            <AccordionSummary expandIcon={expanded[`cate_${cate.id}`] ? <MdKeyboardArrowDown className='shop-accordion-plus' /> : <MdKeyboardArrowDown className='shop-accordion-plus' />}>
                                                <div className="shop-cosmatics"> 
                                                   
                                                </div>
                                            </AccordionSummary>

                                        </div>
                                        <AccordionDetails>
                                            {
                                                cate.children.map(sub_cate=>{
                                                    return(
                                                        <div key={sub_cate.id} className='d-flex'>
                                    <MdOutlineSubdirectoryArrowRight className='cates-icon' />

                                                        <div key={sub_cate.id} className="privacy-the3 mx-2">
                                                            <div className="shop-cosmatics custom-checkbox">
                                                                <input type="checkbox" className="me-2" id={sub_cate.id} checked={selectedCates.includes(sub_cate.id)} onChange={(event)=>{handleChangeCates(event,sub_cate)}} />
                                                                <label htmlFor={sub_cate.id}>{sub_cate.name}</label>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </AccordionDetails>
                                        </Accordion>
                            )}else{
                                return(
                                    <div key={cate.id} className="privacy-the3 my-1">
                                                    <div className="shop-cosmatics custom-checkbox">
                                                        <input type="checkbox" className="me-2" id={cate.id} checked={selectedCates.includes(cate.id)} onChange={(event)=>{handleChangeCates(event,cate)}} />
                                                        <label htmlFor={cate.id}>{cate.name}</label>
                                                    </div>
                                                </div>
                                )
                            }
                        })}
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="shop-grey-line"></div>

{/* ---------------------- price here ------------------------------------------------------------------- */}
            
            {fixedSlider && fixedSlider.max && <div>
             <div className="shop-short mb-2">Price</div>
            <div className="mb-2 d-flex justify-content-between w-14rem">
                <div className="w-full"> {fixedSlider.min}</div>
                <div className="w-full"> {fixedSlider.max} </div>
            </div>
            <Slider value={value} onChange={handleSliderChange} className="w-14rem" range min={fixedSlider.min} max={fixedSlider.max}  />
            <div className="d-flex mt-4 align-items-center">
                <div className="">from </div>
                <div type="text" className="shop-input text-center" >{value[0]}</div>
                <div className="">to</div>
                <div type="text" className="shop-input text-center" >{value[1]} </div>
            </div>
            <div className="shop-grey-line"></div>
            </div>
            }

{/* ---------------------- Colors here ------------------------------------------------------------------- */}
            {filter?.colors && filter?.colors &&<div className='remove-border'>
                <Accordion>
                    <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel2-content" id="panel2-header">
                        
             <div className="shop-short mb-2">Colors</div>

                    </AccordionSummary>
                    <AccordionDetails>
                    {filter.colors.map(color=>( <div key={color.id} className="d-flex align-items-center">
                    <input type="radio" name="colorFilter" value={color.id} checked={selectedColor == color.id} onChange={()=>{handleChangeColor(color)}} />
                    <div className="privacy-the3 ms-2">
                        {color.value}
                    </div>
                </div>))}
                    </AccordionDetails>
                </Accordion>
            <div className="d-flex flex-column">
               
            </div>
            <div className="shop-grey-line"></div>
            </div>}

{/* ---------------------- Brand here ------------------------------------------------------------------- */}
            {
                  filter?.brands && 
                <>
                    <div className="remove-border mb-3">
                        <Accordion  onChange={handleAccordionChange('panel6')} expanded={expanded['panel6']}>
                            <AccordionSummary
                                expandIcon={expanded['panel6'] ? <MdKeyboardArrowDown className='shop-accordion-plus' /> : <MdKeyboardArrowDown className='shop-accordion-plus' />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                            { filter.brands && <div className="shop-category">
                                    Brand
                                </div>}
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="privacy-the3">
                                    <input type="text" className="shop-input2" placeholder="Name of brand"  value={searchTerm} onChange={handleSearchChange} />
                                    { filter.brands.map(brand => {
                                            if (brand.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                                return <div key={brand.id} className="shop-jr">
                                                    <input type="checkbox" name={brand.id} className="me-2" onChange={handleChangeBrand} />
                                                    <div>{brand.name}</div>
                                                </div>
                                            }
                                            return null;
                                        })
                                    }
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="shop-grey-line"></div>
                </> 
            }

{/* ---------------------- size here ------------------------------------------------------------------- */}
            {filter?.sizes.length >0 && <div className="shop-sizes mb-2">
                <div className="shop-short mb-2">Size</div>
                <div className="sizes-container">
                    {filter.sizes.map(size=>( <div key={size.id}  className={`shop-xs ${selectedSize === size.id ? 'selected-size' : ''}`} onClick={()=>{handleChangeSize(size)}}>
                       {size.value}
                    </div>))}

                </div>
            </div>}
        </div>
    )
}
