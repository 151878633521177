import React, { useState, useEffect, useContext,useRef  } from 'react'
import '../css/recover.scss'
import '../css/responsive/recover_res.scss'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { UserContext } from '../context/userContext';
export default function Recover() {
  let { baseURL, token, updateFavLength, defaultImage } = useContext(UserContext);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(''); 

  const [userRecover, setUserRecover] = useState({
    email: "",
});
const successTimeoutRef = useRef(null);
const handleChange = (e) => {
  const { name, value } = e.target;
  setUserRecover(prevState => ({
      ...prevState,
      [name]: value
  }));
  setError(''); 
    setSuccess(''); 
    clearTimeout(successTimeoutRef.current);
};
const handleRecover = () => {
  axios.post(`${baseURL}/password/email`, {
    email: userRecover.email,
}, {
    headers: { 'Authorization': `Bearer ${token}` }
})
  .then((response) => {
      setSuccess('Password recovery email sent successfully.'); // Set success message
      setUserRecover({ email: '' });
      successTimeoutRef.current = setTimeout(() => {
        setSuccess('');
      }, 3000);
  })
  .catch((error) => {
    // Set error message based on different error scenarios
    if (error.response) {
        // Server responded with a status code outside the range of 2xx
        if (error.response.status === 404) {
            setError('Email not found. Please check your email address and try again.');
        } else {
            setError('Failed to recover password. Please try again later.');
        }
    } else if (error.request) {
        // The request was made but no response was received
        setError('Network error. Please check your internet connection and try again.');
    } else {
        // Something happened in setting up the request that triggered an error
        setError('Unexpected error. Please try again.');
    }
});
};
useEffect(() => {
  // Cleanup timeout on component unmount
  return () => {
    clearTimeout(successTimeoutRef.current);
  };
}, []);
  return (
    <div className='privacy'>
      <div className="recover-flower-img">
        <img src="./assests/recover-flower.svg" alt="" />
      </div>
      <div className="recover-wrapper">
        <div className="privacy-privacy" id='recover-recover'>RECOVER PASSWORD</div>
        <div className="login-please">Please enter your e-mail:</div>
        <input type="text" className='login-input' placeholder='Email' name="email" value={userRecover.email} onChange={handleChange}/>
        {error && <div className="recover-error-message">{error}</div>}
        {success && <div className="recover-success-message">{success}</div>} 

        <div className="login-send" onClick={handleRecover}>RECOVER</div>
        <div className="login-please" id='recover-rem'>Remember your password? <Link to='/login' className='recover-link'>Back to login</Link></div>

      </div>
    </div>
  )
}
