import React, { useState, useEffect, useContext, useRef } from 'react'
import '../css/contact.scss'
import '../css/responsive/contact_res.scss'
import axios from 'axios';
import { UserContext } from '../context/userContext';

export default function Contact() {
    let { baseURL, token, updateFavLength, defaultImage } = useContext(UserContext);
    const [success, setSuccess] = useState('');

    const [userContact, setUserContact] = useState({
        name: "",
        phone: "",
        email: "",
        message: ""
    });
    const [formErrors, setFormErrors] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    });
    const successTimeoutRef = useRef(null);

    const handleSend = () => {
        let valid = true;
        const errors = {};

        // Validate each field
        if (!userContact.name) {
            errors.name = 'Name is required.';
            valid = false;
        }
        if (!userContact.phone) {
            errors.phone = 'Phone number is required.';
            valid = false;
        }
        if (!userContact.email) {
            errors.email = 'Email is required.';
            valid = false;
        }
        if (!userContact.message) {
            errors.message = 'Message content is required.';
            valid = false;
        }

        if (valid) {
            axios.post(`${baseURL}/contactus`, {
                name: userContact.name,
                phone: userContact.phone,
                email: userContact.email,
                message: userContact.message,
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            })
            .then((response) => {
                setSuccess('Message sent successfully');
                // Optionally, reset the form fields or show a success message
                setUserContact({
                    name: '',
                    phone: '',
                    email: '',
                    message: ''
                });
                successTimeoutRef.current = setTimeout(() => {
                    setSuccess('');
                }, 3000);
            })
            .catch((error) => {
               
            });
        } else {
            setFormErrors(errors);
        }
    };
    const [formValid, setFormValid] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserContact(prevState => ({
            ...prevState,
            [name]: value
        }));
        // Clear error message when user starts typing
        setFormErrors(prevState => ({
            ...prevState,
            [name]: ''
        }));
    };
    // const handleSend = () => {
    //     if (userContact.name && userContact.phone && userContact.email && userContact.message) {
    //         setFormValid(true);
    //         contactUser();
    //     } else {
    //         setFormValid(false);
    //         alert('Please fill in all required fields.');
    //     }
    // };
    useEffect(() => {
        return () => {
          clearTimeout(successTimeoutRef.current);
        };
      }, []);

    return (
        <div className='privacy'>
            {/* <div className="contact-flower-img">
                <img src="./assests/contact-flower.svg" alt="" />
            </div> */}
            <div className="contact-wrapper">

                <div className="privacy-privacy" id='contact-contact'>
                    CONTACT US
                    <div className="contact-bird-img">
                        <img src="./assests/contact-bird.svg" alt="" />
                    </div>
                </div>
                <div className="contact-left-right">
                    {/* <div className="contact-left">
                        <div className="contact-details">CONTACT DETAILS</div>
                        <div className="contact-grey-line"></div>
                        <img className='contact-logo' src="./assests/contact-logo.svg" alt="" />
                        <div className="contact-boston">Boston/West Palm Beach</div>
                        <div className="contact-google">Google Maps</div>
                    </div> */}
                    <div className="contact-right">
                        <div className="contact-details2">Contact details</div>
                        <div className="contact-details" id='contact-details'>CONTACT FORM</div>
                        <div className="contact-grey-line2"></div>
                        <input type="text" className='contact-input' placeholder='Name*' name="name" value={userContact.name} onChange={handleChange} />
                        {formErrors.name && <span className="contact-error-message">{formErrors.name}</span>}
                        <input type="text" className='contact-input2' placeholder='Telephone*' name="phone" value={userContact.phone} onChange={handleChange} />
                        {formErrors.phone && <span className="contact-error-message">{formErrors.phone}</span>}
                        <input type="text" className='contact-input2' placeholder='Email*' name="email" value={userContact.email} onChange={handleChange} />
                        {formErrors.email && <span className="contact-error-message">{formErrors.email}</span>}
                        <textarea name="message" id="contact-textarea" className='contact-input2' placeholder='Message content *' value={userContact.message} onChange={handleChange}></textarea>
                        {formErrors.message && <span className="contact-error-message">{formErrors.message}</span>}
                        {success && <div className="recover-success-message">{success}</div>}
                        <div className="contact-send" onClick={handleSend}>Send</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
