import React, { useState } from 'react'
import '../css/payment.scss'
import '../css/responsive/payment_res.scss'

import Grey_nav from './grey_Nav'
import PaymentModal from './modal/paymentModal'

export default function Payment() {

    const [showModal, setShowModal] = useState(false);
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);



    const [showPaymentLocation, setShowPaymentLocation] = useState(false);

    const handleAddCard = () => {
        setShowPaymentLocation(true);
    };
    const handleDeleteCard = () => {
        setShowPaymentLocation(false);
    };
    return (
        <div className='address'>
            <div className="recover-flower-img">
                <img src="./assests/recover-flower.svg" alt="" />
            </div>
            <div className="address-wrapper">
                <Grey_nav />

                <div className="address-orders">PAYMENT METHODS</div>
                <div className="address-you">Choose your default payment method.</div>
                <div className="d-flex">
                    <div className="payment-location3" onClick={handleOpenModal}>
                        <div className="address-img">
                            <img src="./assests/card.svg" alt="" />
                        </div>
                        <div className="address-add">Add Payment Method</div>
                    </div>
                    {showPaymentLocation && (
                        <div className="payment-location2">
                            <div className="address-img">
                                <img src="./assests/footer-master.png" alt="" />
                            </div>
                            <div className="payment-visa">Visa ending with 9445</div>
                            <div className="payment-07">07/20</div>
                            <div className="d-flex mt-4">
                                <div className="payment-edit">Edit</div>
                                <div className="payment-delete" onClick={handleDeleteCard}>Delete</div>
                                <input type="checkbox" />
                                <div className="payment-default">Default</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <PaymentModal show={showModal} handleClose={handleCloseModal} handleAddCard={handleAddCard} />
        </div>
    )
}
