import React, { useState, useEffect } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/autoplay';
import ShopFilter from "./shop/shopFilter";
import '../css/exclusives.scss'
import { Link } from "react-router-dom";
import ShopProduct from "./shop/shopProduct";
export default function Summer() {
    const [isWindowClicked, setIsWindowClicked] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex);
    };
  return (
    <div className='address'>
    <div className='grey_nav'>
        <div className="ex-wrapper">
            <div to="/orders" className='grey_nav-nav'>Shortlisted Stories</div>
            <div className="grey_nav-break"></div>
            <div to="/address" className='grey_nav-nav'>Summer Collection</div>
        </div>
    </div>
    <div className="shop-wrapper">
        <div className="shop-wrapper-left-right">
            <div className="shop-wrapper-left">
                <ShopFilter />
            </div>
            <div className="shop-wrapper-right">
                <Swiper
                    className=" shop"
                    spaceBetween={25}
                    slidesPerView={1}
                    navigation={{
                        nextEl: ".home-right",
                        prevEl: ".home-left",
                    }}
                    modules={[Navigation, Autoplay, Pagination]}
                   
                    // autoplay={{ delay: 2000 }}
                    speed={2000}
                    pagination={{
                        clickable: true,
                        bulletClass: "swiper-pagination-bullet",
                        bulletActiveClass: "swiper-pagination-bullet-active",

                    }}
                    onSlideChange={handleSlideChange}
                    activeIndex={activeIndex}
                >
                    <SwiperSlide>

                        <img src="./assests/shop-main.png" className="shop-main-img" alt="" />

                    </SwiperSlide>
                    <SwiperSlide>

                          <img src="./assests/shop-main.png" className="shop-main-img" alt="" />

                    </SwiperSlide>
                    <SwiperSlide>

                          <img src="./assests/shop-main.png" className="shop-main-img" alt="" />

                    </SwiperSlide>
                    <SwiperSlide>

                          <img src="./assests/shop-main.png" className="shop-main-img" alt="" />

                    </SwiperSlide>

                </Swiper >
                {/* <img src="./assests/shop-main2.png" alt="" className="shop-main-img" /> */}
                <div className="shop-necklace">Summer Collection</div>

                <ShopProduct   isWindowClicked={isWindowClicked} setIsWindowClicked={setIsWindowClicked}  />
            </div>
        </div>
    </div>
    <div className="ex-banner">
        <img src="./assests/ex-girl.png" alt="" />
        <div className="ex-banner-abs">
            <div className="ex-get">Get 15 % Off</div>
            <div className="ex-off">off your first order</div>
            <div className="ex-sub">Subscribe to our list and get first access to releases, <p> promos, and exclusive deals!</p></div>
            <input type="text" name="" id="" className="ex-input" placeholder="E-mail address"/>
            <div className="ex-email">EMAIL ME 15% OFF</div>
            <div className="ex-un">Unsubscribe anytime. <p> We respect your Privacy. <Link to='/faq' className="ex-link"> Read FAQs</Link></p>.</div>
        </div>
    </div>
</div>
)
}

