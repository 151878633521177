import React from 'react'
import './email.scss';
import { Link } from 'react-router-dom';
export default function EmailFirst() {
    return (
        <div className='email'>
            <div className="email-container">
                <img src="./assests/email-flower.png" alt="" className='email-flower' />

                <img src="./assests/email-logo.png" alt="" className='email-logo' />
                <img src="./assests/email-croc.png" alt="" className='email-croc' />

                <div className="email-sherry">
                    Hi Sherry,
                </div>
                <div className="email-sorry">
                    I’m so sorry for the delay. We had a bit of a hiccup the first week with emails, which is why you didn’t get the e-mail for the clip.
                </div>
                <div className="email-few">
                    A few days ago the pendant was on its way to the fulfillment center for shipping to you, and you will definitely get a tracking e-mail when it ships! I reached out to Prive Supply so I can provide you with an ETA first thing in the morning.
                </div>
                <div className="email-thanks">
                    Thanks so much for your patience
                </div>
                <div className="email-best">
                    Best Regards,<p>Rae</p>
                </div>
                <div className="email-line"></div>
                <div className="email-social">
                    <a href="https://www.instagram.com/shopshortlisted" target='_blank' rel='noreferrer'>
                        <img src="./assests/email-insta.png" alt="" className='email-insta' />
                    </a>
                    <a href="https://www.tiktok.com/@shopshortlisted" target='_blank' rel='noreferrer'>
                        <img src="./assests/email-tick.png" alt="" className='email-insta' />
                    </a>
                    <img src="./assests/email-face.png" alt="" className='email-insta' />
                </div>
                <div className="email-line"></div>
                <div className="email-copy">
                    © 2024 Shortlisted. All rights reserved.
                </div>
                <div className="email-you">
                    You are receiving this mail because you registered to join Shortlisted platform as a user. This also shows that you agree to our Terms of use and Privacy Policies. If you no longer want to receive mails from use, click the unsubscribe link below to unsubscribe.
                </div>
                <div className="d-flex align-items-center">
                    <Link to='/privacy' className="email-privacy">
                        Privacy policy
                    </Link>
                    <div className="email-circle"></div>
                    <Link to='/terms' className="email-privacy">
                        Terms of service
                    </Link>
                    <div className="email-circle"></div>
                    <div className="email-privacy">
                        Help center
                    </div>
                    <div className="email-circle"></div>
                    <div className="email-privacy">
                        Unsubscribe
                    </div>

                </div>

            </div>
        </div>
    )
}
