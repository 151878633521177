import React from 'react'
// import { useOutletContext } from 'react-router-dom';

export default function Details_Description({ details }) {
    // const details = useOutletContext();

    return (
        <div className='details-Details'>
            <div className="details-match" dangerouslySetInnerHTML={{ __html: details.description }}>
                {/* Match our most-loved beaded pieces with your bubbly personality. Handcrafted in 14k solid gold featuring <p>a responsibly sourced diamond. This one’s an heirloom in the making.</p> */}
            </div>
            
        </div>
    )
}
