import React, { useContext, useState, useEffect,useRef } from 'react';
import '../css/header.scss'
import '../css/responsive/header_res.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination ,Grid} from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/grid';
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/autoplay';
import DetailsModal2 from './modal/detailsModal2';
import axios from 'axios';
import { Squash as Hamburger } from 'hamburger-react'

import 'primeicons/primeicons.css';
import { UserContext } from '../context/userContext';
import { CartContext } from '../context/cartContext';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { IoIosArrowDown } from "react-icons/io";

export default function Header() {
    let { baseURL, token, updateShopTitle, updateHeaderData, headerDate } = useContext(UserContext);
    const [expanded, setExpanded] = useState(false);
    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded({ ...expanded, [panel]: isExpanded });
    };

    const location = useLocation();
    const isShopActive = location.pathname.includes('/shop');
    const isDesignersActive = location.pathname.includes('/designers');

    const [isWrapper2Open, setIsWrapper2Open] = useState(false);
    const [isWrapper3Open, setIsWrapper3Open] = useState(false);
    const [isWrapper4Open, setIsWrapper4Open] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const handleOpenModal2 = () => {
        setShowModal2(true);
        closeall();
    }

    const handleCloseModal2 = () => setShowModal2(false);
    const openSearch = () => {
        setIsSearchOpen(true);
        closeall();
    };

    const openMenu = () => {
        setMenuOpen(true);

    };

    const toggleWrapper2 = () => {
        setIsWrapper3Open(false);
        setIsWrapper4Open(false);
        setIsWrapper2Open(true);
    };


    const toggleWrapper3 = () => {
        setIsWrapper2Open(false);
        setIsWrapper4Open(false);
        setIsWrapper3Open(true);
    };
    const toggleWrapper4 = () => {
        setIsWrapper2Open(false);
        setIsWrapper3Open(false);
        setIsWrapper4Open(true);
    };

    const closeWrapper2 = () => {
        setIsWrapper2Open(false);

    };
    const closeWrapper3 = () => {
        setIsWrapper3Open(false);

    };

    const closeWrapper4 = () => {
        setIsWrapper4Open(false);

    };
    const closeSearch = () => {
        setIsSearchOpen(false);

    };
    const closeMenu = () => {
        setMenuOpen(false);

    };
    const closeall = () => {
        setIsWrapper3Open(false);
        setIsWrapper4Open(false);
        setIsWrapper2Open(false);
    }
    const [activeIndex, setActiveIndex] = useState(0);

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex);
    };
    // const [display, setDisplay] = useState([true, true, true]);

    // const handleCloseClick = index => {
    //     const updatedDisplay = [...display];
    //     updatedDisplay[index] = false;
    //     setDisplay(updatedDisplay);
    // };
    // -----------------------------------------------------------------------------------------------------------
    let { brands, handleSearhChange, favLength } = useContext(UserContext);
    const { cartLength, cartNo ,loadIcon} = useContext(CartContext);

    const navigate = useNavigate();
    const chunkArray = (array, chunkSize) => {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    };

    const brandsToDisplay = brands.slice(0, 12); // Get the first 12 brands
    const groupedBrands = chunkArray(brandsToDisplay, 4);

    //----------------------------------- parent cates---------------------------------------------------
    const [parentCates, setParentCates] = useState(null)

    useEffect(() => {
        axios.get(`${baseURL}/categories/parent`, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => {
                setParentCates(response.data.data);
            })
            .catch((error) => {  })

    }, [baseURL, token])


    /////////////////////////////////////////////////////
    const getSlidesPerView = (width) => {
        if (collections.length < 2) {
            return 1;
        } else if (collections.length < 3) {
            return width >= 900 ? 2 : 1;
        } else if (collections.length < 4) {
            return width >= 900 ? 3 : (width >= 600 ? 2 : 1);
        } else {
            return width >= 900 ? 4 : (width >= 600 ? 3 : 1);
        }
    };

    //----------------------------------- select cate---------------------------------------------------
    const handleSelectCate = (cate) => {
        // 
        navigate(`/products/category/${cate.id}`, { state: { cate } })
        setIsWrapper2Open(false)
        closeMenu()
    }
    //----------------------------------- select brand---------------------------------------------------
    const handleSelectBrand = (flag, brand) => {
        
        navigate(`/products/designers/${brand.id}`)
        setIsWrapper3Open(false);
        closeMenu()
    };
    //----------------------------------- select exclusive---------------------------------------------------
    const handleSelectExclusive = () => {
        navigate('/products/exclusives')
        setIsWrapper3Open(false);
        setIsWrapper4Open(false)
    }
    //----------------------------------- select collection---------------------------------------------------
    const handleSelectCollection = (id, name) => {
        navigate(`/products/collection/${id}`, { state: { name } })
        setIsWrapper4Open(false)
        closeMenu()
    }
    // -----------------------------------------------------------------------------------------------------------
    // collections
    const [collections, setCollections] = useState([]);

    useEffect(() => {

        axios.get(`${baseURL}/collections/not_paginated`, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => {
                if (response.data && response.data.data) {
                    setCollections(response.data.data);
                    // 
                } else {
                   
                }
            })
            .catch((error) => {  })
    }, [baseURL, token])
    // --------------------------------------------------------- search

    const [searchInput, setSearchInput] = useState('');
    const [recentSearches, setRecentSearches] = useState([]);
    const [display, setDisplay] = useState([]);

    useEffect(() => {
        // Retrieve recent searches from local storage
        const storedSearches = JSON.parse(localStorage.getItem('recentSearches')) || [];
        setRecentSearches(storedSearches);
        setDisplay(new Array(storedSearches.length).fill(true));
    }, []);

    const saveToLocalStorage = (searchTerm) => {
        const updatedSearches = [...recentSearches, searchTerm];
        localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
        setRecentSearches(updatedSearches);
        setDisplay(new Array(updatedSearches.length).fill(true));
    };

    const handleRecentSearchClick = (search) => {
        setSearchInput(search);
    };


    const handleInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const handleInputSubmit = () => {
        if (searchInput.trim() !== '') {
            saveToLocalStorage(searchInput);

            setSearchInput('');
            // handleSearhChange(searchInput)
            // updateHeaderData('search', searchInput)

            setIsSearchOpen(false)
            // navigate("/shop");
            navigate(`/products`, { state: { searchInput } })

        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleInputSubmit();
        }
    };

    const handleImageClick = () => {
        handleInputSubmit();
    };

    const handleCloseClick = (index) => {
        const updatedSearches = [...recentSearches];
        updatedSearches.splice(index, 1);
        localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
        setRecentSearches(updatedSearches);
        setDisplay(new Array(updatedSearches.length).fill(true));
    };

    const clickShort = () => {
        handleSelectExclusive();

        closeMenu();
    };
    const [categoriesWithActiveState, setCategoriesWithActiveState] = useState([]);
    const [brandsWithActiveState, setBrandsWithActiveState] = useState([]);
    const [collectionsWithActiveState, setCollectionsWithActiveState] = useState([]);

    useEffect(() => {
        if (!parentCates || !groupedBrands || !collections) {
            return;
        }

        // 
        // 

        const updatedCategories = parentCates.map(cate => ({
            ...cate,
            isActive: location.pathname === `/products/category/${cate.id}`
        }));

        const updatedBrands = groupedBrands.map(group => (
            group.map(brand => ({
                ...brand,
                isActive: location.pathname === `/products/designers/${brand.id}`
            }))
        ));

        const updatedCollections = collections.map(collection => ({
            ...collection,
            isActive: location.pathname === `/products/collection/${collection.id}`
        }));
        if (JSON.stringify(categoriesWithActiveState) !== JSON.stringify(updatedCategories)) {
            setCategoriesWithActiveState(updatedCategories);
        }

        if (JSON.stringify(brandsWithActiveState) !== JSON.stringify(updatedBrands)) {
            setBrandsWithActiveState(updatedBrands);
        }

        if (JSON.stringify(collectionsWithActiveState) !== JSON.stringify(updatedCollections)) {
            setCollectionsWithActiveState(updatedCollections);
        }
    }, [location.pathname, parentCates, groupedBrands, collections]);

    const swiperRef = useRef(null);

    const handleNext = () => {
        const swiperInstance = swiperRef.current.swiper;
        swiperInstance.slideNext(); // Move one slide
        setTimeout(() => {
            swiperInstance.slideNext(); // Move one more slide after a short delay
        }, 500); // Adjust the delay if necessary
    };
    
    const handlePrev = () => {
        const swiperInstance = swiperRef.current.swiper;
        swiperInstance.slidePrev(); // Move one slide
        setTimeout(() => {
            swiperInstance.slidePrev(); // Move one more slide after a short delay
        }, 500); // Adjust the delay if necessary
    };



    const isShopRoute = location.pathname.includes('shop');
    return (
        <div className={`header`}>
            <div className="header-wrapper">
                <Link to='/' className="header-logo">
                    <img src=
                        {`${process.env.PUBLIC_URL}/assests/short-listed.svg`} alt="" />
                </Link>
                <div className="header-nav">
                    <div className="header-padd" onMouseLeave={closeWrapper2} onMouseEnter={toggleWrapper2}>
                        <div className={`header-shop  ${isWrapper2Open ? 'header-underline' : ''} ${location.pathname === `/products` || location.pathname.includes('/cate') ? "header-underline" : ''}`} id='header-shop'>
                            Shop
                            <img src={`${process.env.PUBLIC_URL}/assests/arrow-down.png`} alt="" className='header-arrow-down' />
                        </div>
                        {isWrapper2Open && (
                            <div className="header-wrapper2"  >
                                {parentCates && parentCates.map(cate => (
                                    <div key={cate.id} className="header-added-section" onClick={() => { handleSelectCate(cate) }}>
                                        <div className="header-added-img">
                                            <img src={cate.image} alt="" />
                                        </div>
                                        <div className="header-cosmatics">{cate.name}</div>
                                    </div>
                                ))}

                            </div>
                        )}
                    </div>
                    <div className="header-padd" onMouseEnter={toggleWrapper3} onMouseLeave={closeWrapper3}>
                        <div className={`header-shop  ${isWrapper3Open ? "header-underline" : ''}${location.pathname.includes('/designers') ? 'header-underline' : ''}`} id='header-designers'>
                            Designers
                            <img src={`${process.env.PUBLIC_URL}/assests/arrow-down.png`} alt="" className='header-arrow-down' />
                        </div>
                        {isWrapper3Open && (
                            <div className="header-wrapper3" >
                                <div className="header-wrapper3-cont">
                                    {groupedBrands && groupedBrands.length > 0 && (
                                        <div className="d-flex w-100">
                                            <div className="d-flex flex-column w-100">
                                                <div className="header-brands">Designers</div>

                                                <div className="d-flex">
                                                    {groupedBrands.map((group, index) => (
                                                        <div key={index} className="header-brand-section">
                                                            {group.map((brand) => (
                                                                <div key={brand.id} 
                                                                onClick={() => handleSelectBrand(true, brand)} className="header-jr">
                                                                    {brand.name}
                                                                </div>
                                                                
                                                            ))}
                                                        </div>
                                                    ))}

                                                </div>



                                            </div>
                                            <Link to='/designers' className="" id='brand-see-all' onClick={() => { closeWrapper3()}}>
                                                See All
                                            </Link>
                                        </div>
                                    )}

                                </div>
                            </div>
                        )}
                    </div>
                    <div className="">
                        <div className={`header-shop ${location.pathname.includes('/exclusives') ? "header-underline" : ''}`} onClick={handleSelectExclusive} id='header-exclusive'>Shortlisted Exclusives</div>
                    </div>
                    <div className="header-padd" onMouseEnter={toggleWrapper4} onMouseLeave={closeWrapper4}>
                        <div className={`header-shop  ${isWrapper4Open ? "header-underline" : ''}  ${location.pathname.includes('/collection') ? "header-underline" : ''}`} id='header-short'>Shortlists <img src={`${process.env.PUBLIC_URL}/assests/arrow-down.png`} alt="" className='header-arrow-down' /></div>
                        {isWrapper4Open && (
                            <div className="header-wrapper4">

                                {collections && collections.length > 0 && (
                                    <>
                                        <div className="header-directions-swiper">
                                            <div className="home-back-img swiper-backk" >
                                                <img src={`${process.env.PUBLIC_URL}/assests/home-back.png`} alt="" className='swiper-back' />
                                                <img src={`${process.env.PUBLIC_URL}/assests/home-front.png`} alt="" className="swiper-back-hover" />
                                            </div>
                                            <Swiper
                                           
                                                className="mt-2 "
                                                spaceBetween={25}
                                                slidesPerView={1}
                                                navigation={{
                                                    nextEl: ".swiper-frontt",
                                                    prevEl: ".swiper-backk",
                                                }}
                                                modules={[Grid,Navigation, Autoplay, Pagination]}
                                                // loop={true}

                                                speed={2000}
                                                pagination={{
                                                    clickable: true,
                                                
                                                }}
                                                breakpoints={{
                                                    // when window width is >= 640px
                                                    600: {
                                                        slidesPerView: getSlidesPerView(600),
                                                    },
                                                    900: {
                                                        slidesPerView: getSlidesPerView(900),
                                                    }


                                                }}
                                            >


                                                {collections && collections.map((collection, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div onClick={() => { handleSelectCollection(collection.id, collection.name) }} className="designer-img-text" key={index}>
                                                            <div className="designers-img">
                                                                <img src={collection.image} alt="" />
                                                            </div>
                                                            <div className="designers-rebel">{collection.name}</div>
                                                            <div className="designers-123">({collection.products})</div>
                                                        </div>



                                                    </SwiperSlide>
                                                ))}


                                            </Swiper>
                                            <div className="home-front-img swiper-frontt" >
                                                <img src={`${process.env.PUBLIC_URL}/assests/home-back.png`} alt="" className='swiper-front' />
                                                <img src={`${process.env.PUBLIC_URL}/assests/home-front.png`} alt="" className="swiper-front-hover" />
                                            </div>
                                        </div>

                                        <div className="header-see-all" >
                                            <Link to='collections' className="home-see-all-link" onClick={() => { closeWrapper4() }}> See All</Link>

                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="header-nav2">
                    <div className="header-nav2-image" onClick={openSearch} id='header-search-image'>
                        <img src=
                            {`${process.env.PUBLIC_URL}/assests/search.svg`}
                            alt="" />
                    </div>

                    {token ? (
                        <>
                            <Link to='/favourites' className="header-nav2-image" onClick={closeall}>
                                <img src=
                                    {`${process.env.PUBLIC_URL}/assests/profile.svg`}
                                    alt="" />
                            </Link>
                            <Link to='/favourites' className="header-nav2-image" id='header-love-image' onClick={closeall}>
                                <img src=
                                    {`${process.env.PUBLIC_URL}/assests/love.svg`}
                                    alt="" />
                                {favLength > 0 && (
                                    <div className="fav-abs-no fav-abs-no2"></div>
                                )}

                            </Link>
                            <div className="header-nav2-image" onClick={handleOpenModal2}>
                                <img src=
                                    {`${process.env.PUBLIC_URL}/assests/shopping-bag.svg`}
                                    alt="" />
                                {cartLength > 0 && (
                                    <div className="fav-abs-no">{cartLength}</div>
                                )}


                            </div>
                        </>
                    ) : (
                        <>
                            <Link to='/login' className="header-nav2-image" onClick={closeall}>
                                <img src=
                                    {`${process.env.PUBLIC_URL}/assests/profile.svg`}
                                    alt="" />
                            </Link>
                            
                            <div className="header-nav2-image" onClick={handleOpenModal2}>
                                <img src=
                                    {`${process.env.PUBLIC_URL}/assests/shopping-bag.svg`}
                                    alt="" />
                                {cartLength > 0 && (
                                    <div className="fav-abs-no">{cartLength}</div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="header-wrapper-resp">
                <div className="" onClick={openMenu}>
                    <Hamburger className='header-ham' toggled={menuOpen} toggle={setMenuOpen} distance="sm" size={20} />
                </div>
                <div className="header-nav2-image" onClick={openSearch} id='header-search-image'>

                    <img src=
                        {`${process.env.PUBLIC_URL}/assests/search.svg`}
                        alt="" />
                </div>
                <Link to='/' className="header-logo">
                    <img src=
                        {`${process.env.PUBLIC_URL}/assests/short-listed.svg`}
                        alt="" />
                </Link>
                {token ? (
                    <>
                        <Link to='/favourites' className="header-nav2-image" id='header-profile' onClick={closeall}>
                            <img src=
                                {`${process.env.PUBLIC_URL}/assests/profile.svg`}
                                alt="" />
                        </Link>

                        <div className="header-nav2-image" onClick={handleOpenModal2}>
                            <img src=
                                {`${process.env.PUBLIC_URL}/assests/shopping-bag.svg`}
                                alt="" />
                            {cartLength > 0 && (
                                <div className="fav-abs-no">{cartLength}</div>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <Link to='/login' className="header-nav2-image me-3" onClick={closeall}>
                            <img src=
                                {`${process.env.PUBLIC_URL}/assests/profile.svg`}
                                alt="" />
                        </Link>
                        <div className="header-nav2-image" onClick={handleOpenModal2}>
                            <img src=
                                {`${process.env.PUBLIC_URL}/assests/shopping-bag.svg`}
                                alt="" />
                            {cartLength > 0 && (
                                <div className="fav-abs-no">{cartLength}</div>
                            )}
                        </div>
                    </>
                )}
            </div>





            {isSearchOpen && (
                <div className="header-search" >
                    <div className="header-search-cont">
                        <div className="header-logos">
                            <div className="header-search-logo">
                                <img src=
                                    {`${process.env.PUBLIC_URL}/assests/search-logo.svg`}
                                    alt="" />
                            </div>
                            <div className="header-search-bird">
                                <img src=
                                    {`${process.env.PUBLIC_URL}/assests/search-bird.svg`}
                                    alt="" />
                            </div>


                        </div>
                        <div className="header-abs-search">Search</div>
                        <img src=
                            {`${process.env.PUBLIC_URL}/assests/search-close2.svg`}
                            alt="" className='header-search-close-img2' onClick={closeSearch} />
                        <div className="header-search-cont-img">
                            <input
                                type="text"
                                id="searchInput"
                                className="header-search-input"
                                placeholder="Type something"
                                value={searchInput}
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                            />
                            <label htmlFor="searchInput" onClick={handleImageClick}>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assests/header-search.svg`}
                                    alt=""
                                    className="header-search-img"
                                />
                            </label>
                        </div>

                        <div className="header-search-recent">Recent Searches</div>
                        <div className="header-search-recents">
                            {recentSearches.slice(-5).map((search, index) => (
                                <div
                                    key={index}
                                    className="header-search-golden"
                                    style={{ display: display[recentSearches.length - 5 + index] ? 'flex' : 'none' }}
                                    onClick={() => handleRecentSearchClick(search)}
                                >
                                    {search}
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assests/search-close.svg`}
                                        alt=""
                                        className="header-search-close-img"
                                        onClick={() => handleCloseClick(recentSearches.length - 5 + index)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* <img src="./assests/search-flower.svg" alt="" className='header-search-flower' /> */}
                </div>
            )}


            {menuOpen && (
                <div className="header-search" id='menu-search'>
                    <div className="header-search-cont" id='header-menu-cont'>
                        <div className="header-logos">
                            <div className="header-search-logo">
                                <img src=
                                    {`${process.env.PUBLIC_URL}/assests/short-menu.svg`}
                                    alt="" />
                            </div>



                        </div>
                        <div className="header-abs-search">Menu</div>
                        <img src=
                            {`${process.env.PUBLIC_URL}/assests/search-close2.svg`}
                            alt="" className='header-search-close-img2' onClick={closeMenu} />
                        <div className="header-search-cont-img">


                        </div>
                        <div className="header-menu-recents">

                            <Link to='/' className={`header-menu-recent ${location.pathname === '/' ? 'header-menu-orange' : ''}`} onClick={closeMenu}>
                                {location.pathname === '/' && (
                                    <div className="header-orange-left-line"></div>
                                )}
                                Home</Link>
                            <div className="header-accordion" id='header-acc'>
                                <Accordion onChange={handleAccordionChange('panel6')} expanded={expanded['panel6']}>
                                    <AccordionSummary
                                        expandIcon={expanded['panel6'] ? <IoIosArrowDown className='FAQ-accordion-plus' id='header-down' /> : <IoIosArrowDown className='FAQ-accordion-plus' id='header-up' />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                    >
                                        <div className="privacy-contact3" id='header-support'>
                                            {location.pathname === `/products` || location.pathname.includes('/cate') && <div className="header-orange-left-line"></div>}
                                            Shop


                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>




                                        <div className="footer-acc-nav">
                                            {categoriesWithActiveState.map(cate => (

                                                <div key={cate.id} className={`header-added-section `} onClick={() => { handleSelectCate(cate) }}>

                                                    <div className={`header-boston ${cate.isActive ? 'header-menu-orange' : ''}`}>{cate.name}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="header-accordion" id='header-acc'>
                                <Accordion onChange={handleAccordionChange('panel7')} expanded={expanded['panel7']}>
                                    <AccordionSummary
                                        expandIcon={expanded['panel7'] ? <IoIosArrowDown className='FAQ-accordion-plus' id='header-down' /> : <IoIosArrowDown className='FAQ-accordion-plus' id='header-up' />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                    >
                                        <div className="privacy-contact3" id='header-support'>
                                            {location.pathname.includes('/designers') && <div className="header-orange-left-line"></div>}
                                            Designers
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                        <div className="footer-acc-nav">
                                            {brandsWithActiveState.slice(0, 2).map((group, index) => (

                                                <div key={index} className="header-brand-section">
                                                    {group.map((brand) => (
                                                        <div key={brand.id} onClick={() => handleSelectBrand(true, brand)} className={`header-boston ${brand.isActive ? 'header-menu-orange' : ''}`} >
                                                            {brand.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                            <Link to='/designers' className="" id='brand-see-all' onClick={closeMenu}>
                                                See All
                                            </Link>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>


                            <div className={`header-menu-recent ${location.pathname === '/products/exclusives' ? 'header-menu-orange' : ''}`} onClick={clickShort}>
                                {location.pathname === '/products/exclusives' && (
                                    <div className="header-orange-left-line"></div>
                                )}
                                Shortlisted Exclusives
                            </div>
                            <div className="header-accordion" id='header-acc'>
                                <Accordion onChange={handleAccordionChange('panel8')} expanded={expanded['panel8']}>
                                    <AccordionSummary
                                        expandIcon={expanded['panel8'] ? <IoIosArrowDown className='FAQ-accordion-plus' id='header-down' /> : <IoIosArrowDown className='FAQ-accordion-plus' id='header-up' />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                    >
                                        <div className="privacy-contact3" id='header-support'>
                                            {location.pathname.includes('/collection') && <div className="header-orange-left-line"></div>}
                                            Shortlists
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="footer-acc-nav">
                                            {collectionsWithActiveState && collectionsWithActiveState.map((collection, index) => (
                                                <div onClick={() => { handleSelectCollection(collection.id, collection.name) }} className="header-brand-section" key={index}>

                                                    <div className={`header-boston ${collection.isActive ? 'header-menu-orange' : ''}`}>{collection.name}</div>

                                                </div>
                                            ))}
                                            <Link to='/collections' className="" id='brand-see-all' onClick={closeMenu}>
                                                See All
                                            </Link>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            {/* <div className="header-menu-recent">Shortlisted Stories</div> */}
                        </div>
                    </div>
                </div>
            )}



            <DetailsModal2 show={showModal2} handleClose={handleCloseModal2} />

        </div>
    )
}
