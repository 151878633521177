import React, { useState, useEffect, useContext } from 'react'
import '../css/home.scss'
import '../css/responsive/home_res.scss'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Grid, EffectFlip, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-flip';
import 'swiper/css/autoplay';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { UserContext } from '../context/userContext';
import axios from 'axios';
import { InstagramEmbed } from 'react-social-media-embed';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Row } from 'react-bootstrap';
import InstagramSwiperSlide from './InstagramSwiperSlide';

export default function Home() {
    const extractInstagramUrl = (link) => {
        const urlMatch = link.match(/https:\/\/www.instagram.com\/p\/[A-Za-z0-9_-]+\/?/);
        return urlMatch ? urlMatch[0] : null;
    };
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(''); // 'success' or 'error'
    const [settings, setSettings] = useState(''); // 'success' or 'error'
    const [homePics, setHomePics] = useState('');
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const success = params.get('success');

        if (success === 'true') {
            setMessage('Order placed successfully');
            setMessageType('success');
        } else if (success === 'false') {
            setMessage('Checkout was canceled');
            setMessageType('error');
        }

        const timer = setTimeout(() => {
            setMessage('');
            setMessageType('');
        }, 3000);

        return () => clearTimeout(timer);
    }, []);


    const [activeIndex, setActiveIndex] = useState(0);
    const navigate = useNavigate();

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex);
    };
    let { baseURL, token, updateFavLength, defaultImage, clearHeaderData } = useContext(UserContext);

    const [featProducts, setFeatProducts] = useState([])
    const [arrivals, setArrivals] = useState([])
    const [spot, setSpot] = useState([])

    const [copiedProductIds, setCopiedProductIds] = useState({});

    const urlToCopy = (arr) => `${window.location.href}details/${arr}`;
    const handleCopy = (productId) => {
        setCopiedProductIds((prev) => ({ ...prev, [productId]: true }));
        setTimeout(() => {
            setCopiedProductIds((prev) => ({ ...prev, [productId]: false }));
        }, 2000);
    };

    useEffect(() => {
        axios.get(`${baseURL}/home-banners`)
            .then((response) => {
                setHomePics(response.data.data);
            })

        axios.get(`${baseURL}/collections/featured`)
            .then((response) => {
                setFeatProducts(response.data.data);

            })
            .catch((error) => { })
        axios.get(`${baseURL}/web_settings`)
            .then((response) => {
                setSettings(response.data.data);

            })
            .catch((error) => { })


        axios.get(`${baseURL}/products/new_arrival`, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => {
                setArrivals(response.data.data);

            })
            .catch((error) => { })


        axios.get(`${baseURL}/social_spot_light`)
            .then((response) => {
                if (response.data && response.data.data) {
                    setSpot(response.data.data);
                    // 
                } else {

                }
            })
            .catch((error) => {

            });

    }, [baseURL, token])

    const updateFavorite = (id, variationValueId) => {

        const updatedProducts = arrivals.map(product => {
            if (product.product_id === id && product.variation_attributes.variation_value_id === variationValueId) {
                // 
                return {
                    ...product,
                    isFavorite: !product.isFavorite

                };

            }
            return product;

        });
        // alert('reach here',updatedProducts);
        setArrivals(updatedProducts);
    };


    const removeFromFav = (id, v_id, isFav) => {
        if (isFav) {
            axios.delete(`${baseURL}/favorites`, {
                data: { product_id: id, variation_value_id: v_id },
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then(res => {

                    updateFavorite(id, v_id);
                    updateFavLength(false);
                })
                .catch();
        } else {
            axios.post(`${baseURL}/favorites/add`, {
                product_id: id, variation_value_id: v_id
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then(response => {

                    updateFavorite(id, v_id);
                    updateFavLength(true);
                })
                .catch();
        }
    };

    const handleImageError = (e) => {
        e.target.src = defaultImage;
    };
    const oddArrivals = arrivals.filter((_, index) => index % 2 === 0);
    const evenArrivals = arrivals.filter((_, index) => index % 2 !== 0);
    const arrivalPairs = oddArrivals.map((oddArrival, index) => ({
        oddArrival,
        evenArrival: evenArrivals[index],
    }));

    const navigateTOCollection = (id, name) => {
        navigate(`/products/collection/${id}`, { state: { name } })
    }


    return (
        <div className='home'>
            <div className="home-wrapper-1">
                {message && (
                    <div className={`message ${messageType}`}>
                        {message}
                    </div>
                )}
                <div className="home-pink-background">
                    <div className="home-pink-img">
                        {/* <img src="./assests/home-rightImage.svg" alt="" /> */}
                        <img src={homePics.right_image} alt="" />

                    </div>
                    <Link to='/products' className="home-shop-now" onClick={() => { clearHeaderData() }}>
                        {homePics.button_text}
                    </Link>
                </div>
                <div className="home-first-discover">
                    <div className="home-first-img">
                        {/* <img src="./assests/home-leftImage.svg" alt="" /> */}
                        <img src={homePics.left_image} alt="" />
                    </div>
                    {homePics&&(
                    <div className="home-discover">
                        {/* Welcome to <p> Shortlisted</p> */}
                        {homePics.title.split(' ')[0]} {homePics.title.split(' ')[1]}<br />
                        <p className="home-discover-shortlisted">
                            {homePics.title.split(' ').slice(2).join(' ')}
                        </p>
                    </div>)}
                    <div className="home-modern">
                        {/* No more spending hours searching <p> for "it" We've created the shortlist <p> for you.</p></p> */}
                        {homePics.info}
                    </div>
                </div>
                <div className="home-middle-img">
                    {/* <img src="./assests/home-middleImage.svg" alt="" /> */}
                    <img src={homePics.middle_image} alt="" />
                </div>

            </div>

            <div className="home-wrapper-2">
                {/* <div className="home-flower-img">
                    <img src="./assests/home-flower-1.svg" alt="" />
                </div> */}
                <div className="home-wrapper-3">
                    {/* <div className="home-featured">
                        Featured Products
                    </div> */}
                    <div className="home-swipers" id='home-swipers10'>
                        <Swiper
                            className="mt-2 pb-5 pt-3 home-pag"
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation={{
                                nextEl: ".swiper-front",
                                prevEl: ".swiper-back",
                            }}
                            modules={[Navigation, Autoplay, Pagination]}

                            // autoplay={{ delay: 2000 }}
                            speed={2000}
                            pagination={{
                                clickable: true,
                                el: '.swiper-pagination2',

                            }}
                            onSlideChange={handleSlideChange}
                            activeindex={activeIndex}
                            breakpoints={{
                                // when window width is >= 640px
                                600: {
                                    slidesPerView: 2,
                                },
                                900: {
                                    slidesPerView: 3,
                                }

                            }}
                        >
                            <div className="home-products-imgs">
                                {featProducts.length > 0 && featProducts.map((featProduct, index) => (
                                    <SwiperSlide key={featProduct.id}>
                                        <div className="home-product-1" style={{ marginTop: index === 1 ? '30px' : (index === 2 ? '60px' : '0') }}>
                                            <div className="home-product-img-1" id='home-product-change-size'>
                                                <img src={featProduct.image} alt="" onError={handleImageError} />
                                            </div>
                                            <div className="home-rings">{featProduct.name}</div>
                                            <div className="home-see-all" onClick={() => { navigateTOCollection(featProduct.id, featProduct.name) }} >
                                                <div className="home-see-all-p" > See All</div>
                                                <img src="./assests/home-right2.png" alt="" className='home-see-all-img' />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}

                            </div>
                            <div className="swiper-pagination"></div>
                        </Swiper>
                    </div>
                </div>
            </div>


            <div className="home-wrapper3">

                <div className="home-wrapper-3">
                    <div className="home-new-arrival-back-front-imgs">
                        <div className="home-new-arrival">
                            {settings?.section3}
                        </div>
                        {/* {arrivals.length > 0 && ( */}
                        <div className="home-back-front-imgs">
                            <div className="home-back-img swiper-backk" >
                                <img src="./assests/home-back.png" alt="" className='swiper-back' />
                                <img src="./assests/home-front.png" alt="" className="swiper-back-hover" />
                            </div>
                            <div className="home-front-img swiper-frontt" >
                                <img src="./assests/home-back.png" alt="" className='swiper-front' />
                                <img src="./assests/home-front.png" alt="" className="swiper-front-hover" />
                            </div>
                        </div>
                        {/* )} */}

                    </div>
                    <div className="home-swipers" id='home-test'>

                        <Swiper
                            className="mt-2 pb-5 pt-3 home-pag"
                            spaceBetween={10}
                            slidesPerView={1}
                            // grid={{
                            //     rows: 2,fill: "row"
                            // }}
                            navigation={{
                                nextEl: ".swiper-frontt",
                                prevEl: ".swiper-backk",
                            }}
                            modules={[Grid, Navigation, Autoplay, Pagination]}

                            speed={2000}
                            pagination={{
                                clickable: true,

                            }}
                            breakpoints={{
                                // when window width is >= 640px
                                600: {
                                    slidesPerView: 2,
                                },
                                900: {
                                    slidesPerView: 3,
                                }

                            }}
                        >

                            <div className="home-products-swiper2">
                                {arrivalPairs.map((pair, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="home-menu2">
                                            {copiedProductIds[pair.oddArrival.product_id] && <span className='details-link'>Link copied!</span>}

                                            {token && (
                                                <>
                                                    <img
                                                        src={pair.oddArrival.isFavorite ? `${process.env.PUBLIC_URL}/assests/love3.svg` : `${process.env.PUBLIC_URL}/assests/love4.svg`}
                                                        alt=""
                                                        className='fav-love' id='home-love'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            removeFromFav(pair.oddArrival.product_id, pair.oddArrival.variation_attributes.variation_value_id, pair.oddArrival.isFavorite);
                                                        }}
                                                    />
                                                    <CopyToClipboard text={urlToCopy(pair.oddArrival.product_id)} onCopy={() => handleCopy(pair.oddArrival.product_id)}>
                                                        <img src="./assests/link.svg" alt="" className='fav-love2' />
                                                    </CopyToClipboard>
                                                </>
                                            )}
                                            {!token && (
                                                <CopyToClipboard text={urlToCopy(pair.oddArrival.product_id)} onCopy={() => handleCopy(pair.oddArrival.product_id)}>
                                                    <img src="./assests/link.svg" alt="" className='fav-love2' />
                                                </CopyToClipboard>
                                            )}
                                            <div className="fav-menu-cont1" id='home-menu-cont1'>
                                                <div className="details-swiper-img-1" id='home-swiper-img-1'>
                                                    <img src={pair.oddArrival.images && pair.oddArrival.images[0] && pair.oddArrival.images[0].image ? pair.oddArrival.images[0].image : './assests/swiper-2.png'}
                                                        onError={handleImageError} onClick={() => { navigate(`/details/${pair.oddArrival.product_id}`); }} />

                                                </div>
                                                <div className="home-golden">{pair.oddArrival.product_name}
                                                    <div className="home-price">${pair.oddArrival.variation_attributes.price}</div>
                                                </div>
                                                {token && (
                                                    <div className="fav-add-col" onClick={() => { navigate(`/details/${pair.oddArrival.product_id}`); }}>
                                                        ADD TO CART
                                                    </div>
                                                )}
                                                {!token && (
                                                    <div className="fav-add-col" onClick={() => { navigate(`/details/${pair.oddArrival.product_id}`); }}>
                                                        ADD TO CART
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {pair.evenArrival && (
                                            <div className="home-menu2">
                                                {copiedProductIds[pair.evenArrival.product_id] && <span className='details-link'>Link copied!</span>}
                                                {token && (
                                                    <>
                                                        <img
                                                            src={pair.evenArrival.isFavorite ? `${process.env.PUBLIC_URL}/assests/love3.svg` : `${process.env.PUBLIC_URL}/assests/love4.svg`}
                                                            alt=""
                                                            className='fav-love' id='home-love'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                removeFromFav(pair.evenArrival.product_id, pair.evenArrival.variation_attributes.variation_value_id, pair.evenArrival.isFavorite);
                                                            }}
                                                        />
                                                        <CopyToClipboard text={urlToCopy(pair.evenArrival.product_id)} onCopy={() => handleCopy(pair.evenArrival.product_id)}>
                                                            <img src="./assests/link.svg" alt="" className='fav-love2' />
                                                        </CopyToClipboard>
                                                    </>
                                                )}
                                                {!token && (
                                                    <CopyToClipboard text={urlToCopy(pair.evenArrival.product_id)} onCopy={() => handleCopy(pair.evenArrival.product_id)}>
                                                        <img src="./assests/link.svg" alt="" className='fav-love2' />
                                                    </CopyToClipboard>
                                                )}
                                                <div className="fav-menu-cont1" id='home-menu-cont2'>
                                                    <div className="details-swiper-img-1" id='home-swiper-img-2'>
                                                        <img src={pair.evenArrival.images && pair.evenArrival.images[0] && pair.evenArrival.images[0].image ? pair.evenArrival.images[0].image : './assests/swiper-2.png'}
                                                            onError={handleImageError} onClick={() => { navigate(`/details/${pair.evenArrival.product_id}`); }} />

                                                    </div>
                                                    <div className="home-golden">{pair.evenArrival.product_name}
                                                        <div className="home-price">${pair.evenArrival.variation_attributes.price}</div>
                                                    </div>
                                                    {token && (
                                                        <div className="fav-add-col" onClick={() => { navigate(`/details/${pair.evenArrival.product_id}`); }}>
                                                            ADD TO CART
                                                        </div>
                                                    )}
                                                    {!token && (
                                                        <div className="fav-add-col" onClick={() => { navigate(`/login`); }}>
                                                            ADD TO CART
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </SwiperSlide>
                                ))}

                            </div>




                        </Swiper>

                    </div>





                </div>
            </div>







            <div className="home-wrapper-4">

                <div className="home-wrapper4-cont">


                    <div className="home-social">
                        #shopshortlisted By You
                    </div>
                    {/* <div className="home-insta">
                        Insta-Tik Tags
                    </div> */}
                    <div className="home-directions-swiper" style={{ padding: '10px' }}>
                        <div className="home-left-img" style={{ padding: '10px' }}>
                            <img src="./assests/home-right.png" alt="" className='home-left' />
                        </div>
                        <Swiper
                            className="mt-2 "
                            spaceBetween={20}
                            slidesPerView={1}
                            navigation={{
                                nextEl: ".home-right",
                                prevEl: ".home-left",
                            }}
                            modules={[Navigation, Autoplay]}

                            speed={2000}
                            breakpoints={{
                                800: {
                                    slidesPerView: 2,
                                },

                            }}
                        >
                            {spot.map((pair, index) => (
                                <SwiperSlide key={index}>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div style={{ overflow: "hidden" }} className='test-insta'>
                                            <InstagramEmbed url={extractInstagramUrl(pair.link)} />
                                            <div className="overlay"></div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}

                        </Swiper>
                        <div className="home-right-img" style={{ padding: '10px' }}>
                            <img src="./assests/home-right.png" alt="" className='home-right' />
                        </div>
                    </div>


                </div >

            </div >
        </div >
    )
}
