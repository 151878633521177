import React, { useEffect, useState, useContext } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFlip, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/autoplay';
import { IoCloseOutline } from "react-icons/io5";
import { UserContext } from '../../context/userContext';

import CartProduct from '../cart/cartProduct';
import { CartContext } from '../../context/cartContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function DetailsModal2({ show, handleClose }) {
    const navigate = useNavigate()
    const [addNote, setaddNote] = useState(false);
    const handleSave = () => {
        setaddNote(false); // Hide the note section when SAVE is clicked
    };
    const [activeIndex, setActiveIndex] = useState(0);
    const { cartLength,cartNo } = useContext(CartContext);

    let { baseURL, token, updateFavLength, defaultImage, updateShopTitle, setExclusive, updateHeaderData, headerDate } = useContext(UserContext);
    const handleNavigateToCart = () => {
        handleClose();
        navigate('/cart');
    };
    const handleCloseCart = () => {
        handleClose();
       
    };
   



    return (
        <div>
            {cartLength >= 1 && (
                <Modal show={show} onHide={handleClose} className="custom-modal">

                    <Modal.Body>
                        <div className=" addressModal-add-close">
                            <div className="addressModal-add">
                                <img src=
                                    {`${process.env.PUBLIC_URL}/assests/shopping-bag.png`}

                                    alt="" className='detailsModal-shopping-bag' />
                                {cartLength} ITEMS</div>
                            <IoCloseOutline className='modal-close' onClick={handleClose} />
                        </div>
                        <div className="detailsModal-body-cont">

                            <div className="addressModal-add-mobile">
                                {cartLength} ITEMS
                                <img src=
                                    {`${process.env.PUBLIC_URL}/assests/shopping-bag.png`}

                                    alt="" className='detailsModal-shopping-bag' />
                            </div>
                            <div className="detailsModal-you">  You are eligible for free shipping!</div>
                            <div className="detailsModal-line">
                                <img src=
                                    {`${process.env.PUBLIC_URL}/assests/modal-bird.png`}
                                    alt="" className='detailsModal-bird' />
                            </div>



                            <CartProduct handleCloseCart={handleCloseCart}/>




                            <div className="detailsModal-add-shipping">
                                {/* <div className="detailsModal-add" onClick={setaddNote}>Add order note</div> */}
                                <div className="detailsModal-shipping">shipping & taxes** calculated at checkout</div>
                            </div>
                            <div className="detailsModal-show" onClick={handleNavigateToCart}>SHOW CART</div>
                            {addNote && (
                                <div className="detailsModal-add-note-section">
                                    <div className="detailsModal-add2">Add order note</div>
                                    <textarea name="" id="" className='cart-textarea' placeholder='How can we help you?'></textarea>
                                    <div className="detailsModal-save" onClick={handleSave}>SAVE</div>
                                </div>
                            )}
                        </div>

                    </Modal.Body>
                    {/* <Modal.Footer>
          
        </Modal.Footer> */}
                </Modal>

                    )}

        </div>
    )
}
