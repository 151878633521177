import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../context/userContext';

const NavigateToHome = ({ children }) => {
  const { token } = useContext(UserContext);

  if (token) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default NavigateToHome;
