import './App.css';
import FAQ from './components/FAQ';
import Cart from './components/cart';
import Checkout from './components/checkout';
import Collections from './components/collections';
import Contact from './components/contact';
import Designers from './components/designers';
import Details from './components/details';
import Details_Description from './components/details_Description';
import Details_Details from './components/details_Details';
import Footer from './components/footer';
import Header from './components/header';
import Home from './components/home';
import Login from './components/login';
import Orders from './components/orders';
import Payment from './components/payment';
import Privacy from './components/privacy';
import Recover from './components/recover';
import RenamePass from './components/renamePass';

import Refund from './components/refund';
import Register from './components/register';
import Return from './components/return';
import Shipping from './components/shipping';
import Terms from './components/terms';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Shop from './components/shop/shop';
import UserProvider from './context/userProvider';
import Fav from './components/fav/fav';
import Summer from './components/summer';
import './fonts/fonts.css'
import Address from './components/address/address';
import { CartProvider } from './context/cartContext';
import Return_request from './components/return_request';
import React, { useState, useEffect, useContext } from 'react'
import New_Shop from './components/shop-new/shop-new';
import Brand from './components/shop-new/brand';
import ShortlistedExclusives from './components/shop-new/shortlisted-exclusives';
import ProductsCollection from './components/shop-new/collection';
import Categories from './components/shop-new/cates';
import { UserContext } from './context/userContext';
import NavigateToHome from './components/navigateToHome';
import ProtectedRoute from './components/ProtectedRoute';
import EmailFirst from './components/email/EmailFirst';
import EmailSecond from './components/email/EmailSecond';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  let { token } = useContext(UserContext);
  useEffect(() => {
    // Check if the pathname does not include '/details/:id/' with nested routes
    if (!/\/details\/[^\/]+\/(description|details)/.test(pathname)) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
  return null;
}

function App() {
  return (
    <Router>
      <UserProvider>
        <CartProvider>
          <ScrollToTop />
          <AppContent />
        </CartProvider>
      </UserProvider>
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  // Check if the current route is not '/checkout'
  const showFooter = location.pathname !== '/checkout' && location.pathname !== '/email' && location.pathname !== '/email2';
  const hideHeader = location.pathname === '/email' || location.pathname === '/email2';

  return (
    <div className="App">
      {!hideHeader && <Header />}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/details/:id' element={<Details />}  >
          {/* <Route index element={<Navigate to="description" replace />} /> */}
          <Route path='description' element={<Details_Description />} />
          <Route path='details' element={<Details_Details />} />
        </Route>
        <Route path="/checkout" element={<Checkout />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/terms' element={<Terms />} />
        <Route path="/orders" element={<ProtectedRoute><Orders /></ProtectedRoute>} />
        <Route path="/address" element={<ProtectedRoute><Address /></ProtectedRoute>} />
        <Route path="/payment" element={<ProtectedRoute><Payment /></ProtectedRoute>} />
        <Route path="/favourites" element={<ProtectedRoute><Fav /></ProtectedRoute>} />
        {/* <Route path='/faq' element={<FAQ />} /> */}
        <Route path='/contact' element={<Contact />} />
        <Route path="/login" element={<NavigateToHome><Login /></NavigateToHome>} />
        <Route path="/register" element={<NavigateToHome><Register /></NavigateToHome>} />
        <Route path='/return' element={<Return />} />
        <Route path='/refund' element={<Refund />} />
        <Route path='/shipping' element={<Shipping />} />
        <Route path='/designers' element={<Designers />} />
        <Route path='/recover' element={<Recover />} />
        <Route path='/renamePass' element={<RenamePass />} />
        <Route path='/return_request/:orderid' element={<Return_request />} />
        <Route path='/collections' element={<Collections />} />
        <Route path="/cart" element={<Cart />} />
        <Route path='/shop' element={<Shop />} />
        <Route path='/summer' element={<Summer />} />
        <Route path='/products' element={<New_Shop />} />
        <Route path='/products/category/:id' element={<Categories />} />
        <Route path='/products/designers/:id' element={<Brand />} />
        <Route path='/products/collection/:id' element={<ProductsCollection />} />
        <Route path='/products/exclusives' element={<ShortlistedExclusives />} />
        <Route path='/email' element={<EmailFirst />} />
        <Route path='/email2' element={<EmailSecond />} />

      </Routes>
      {showFooter && <Footer />}
    </div>
  );
}
export default App;