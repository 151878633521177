import React, { useContext, useState } from 'react'
import { UserContext } from '../../context/userContext';
import Product from '../fav/product';
import axios from 'axios';

export default function ShopProduct({ isWindowClicked, setIsWindowClicked , products , pagination ,handleFilterObj , handelUpdateProducts }) {
    const handleClickWindow = () => {
        setIsWindowClicked(true);
    };

    const handleClickBurger = () => {
        setIsWindowClicked(false);
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    let { baseURL,token,updateFavLength } = useContext(UserContext);

    const updateFavorite = (id, variationValueId) => {
        const updatedProducts = products.map(product => {
            if (product.id === id && product.variation_attributes.variation_value_id === variationValueId) {
                return {
                    ...product,
                    isFavorite: !product.isFavorite 
                };
            }
            return product;
        });

        handelUpdateProducts(updatedProducts);
    };
  
// ---------------------- delete product from Favorites ---------------------
  const removeFromFav = (id,v_id,isFav) => {
    if(isFav){
        axios.delete(`${baseURL}/favorites`, {data: { product_id: id, variation_value_id: v_id },headers: { 'Authorization': `Bearer ${token}` }})
        .then(res => {
            
            updateFavorite(id,v_id,);
            updateFavLength(false);
        })
        .catch();
    }
    else{
        axios.post(`${baseURL}/favorites/add`,{product_id: id, variation_value_id: v_id },{ headers: { 'Authorization': `Bearer ${token}` } })
        .then((response) => {
            
            updateFavorite(id,v_id,);
            updateFavLength(true);
        })
        .catch();
}
  };
    

  const [selectedValue, setSelectedValue] = useState('asc');
  const handleChange = (event) => {
    const { name, value } = event.target;
    setSelectedValue(value);
    handleFilterObj(name, value);
};

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            {products.length>0 && <div className="fav-items-sort">
                <div className="fav-4-items">Showing {pagination.from}-{pagination.to} of {pagination.totalRecords} results </div>
                <div className="fav-sort-default">


                    <div className="fav-sort">Sort by</div>
                    <select
                        name="sort"
                        className="sort-select"
                        value={selectedValue}
                        onChange={handleChange}
                    >
                        <option value="asc">Asc</option>
                        <option value="desc">Desc</option>
                    </select>
                    <img src={`${process.env.PUBLIC_URL}/assests/square.svg`} alt="" className='fav-window' onClick={handleClickBurger} />

                    <img src={`${process.env.PUBLIC_URL}/assests/lines.svg`} alt="" className='fav-window' onClick={handleClickWindow} />

                </div>
            </div>}
            <div className={"fav-details-images " + (isWindowClicked && 'fav-details-col')}>
                {
                 products &&  products.map(product=>{
                    return <Product key={product.variation_attributes.product_variation_value} product={product} isWindowClicked={isWindowClicked} removeFromFav={removeFromFav}/>
                    })
                }
                
              

            </div>
        </div>
    )
}
