import React, { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { UserContext } from '../../context/userContext';
export default function Product({ product, isWindowClicked, removeFromFav, title,pagination }) {
    const navigate = useNavigate();
    const location = useLocation()
    const pathname = location.pathname

    let { token, defaultImage } = useContext(UserContext);
    const handleImageError = (e) => {
        e.target.src = defaultImage;
    };
    const [copied, setCopied] = useState(false);
    const handleCopy = (e, id) => {
        e.stopPropagation();
        navigator.clipboard.writeText(`https://shortlisted.msol.dev/details/${id}`);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000); // Reset the copied state after 2 seconds
    };
    const truncateName = (name) => {
        const words = name.split(' ');
        if (words.length > 3) {
            return words.slice(0, 3).join(' ') + '...';
        }
        return name;
    };
    return (
        <div className={"fav-menu col-5 col-sm-5 col-lg-3 " + (isWindowClicked && 'fav-menu-col')}>
            <div className={" fav-menu-cont1 " + (isWindowClicked && 'fav-menu-cont1-col')}>
                {
                    token && <>
                        {!isWindowClicked && <img src={product.isFavorite ? `${process.env.PUBLIC_URL}/assests/love3.svg` : `${process.env.PUBLIC_URL}/assests/love4.svg`} alt="" className={'fav-love'} onClick={(e) => { e.stopPropagation(); removeFromFav(product.product_id, product.variation_attributes.variation_value_id, product.isFavorite) }} />}
                        {!isWindowClicked && <img src={`${process.env.PUBLIC_URL}/assests/link.svg`} id='shop-love2' alt="" className='fav-love2' onClick={(e) => { handleCopy(e, product.product_id); }} />}
                    </>
                }
                {!token && <>
                    {!isWindowClicked && <img src={`${process.env.PUBLIC_URL}/assests/link.svg`} id='shop-love2' alt="" className='fav-love2' onClick={(e) => { handleCopy(e, product.product_id); }} />}
                </>}
                {token && (<div className={"details-swiper-img-1 " + (isWindowClicked && 'details-wiper-img-1-col')} id='fav-swiper-img-1' onClick={() => {navigate(`/details/${product.product_id}`, { state: { pathname, title,pagination } });}}>
                    {product.images && product.images.length > 0 && product.images[0].image ? (
                        <img
                            src={product.images[0].image}
                            alt="Product"
                            onError={handleImageError}
                        />
                    ) : (
                        <img
                            src={defaultImage}
                            alt="Default Product"
                        />
                    )}

                </div>
                )}
                {!token && (<div className="details-swiper-img-1" id='fav-swiper-img-1' onClick={() => {navigate(`/details/${product.product_id}`, { state: { pathname, title,pagination } }); }}>
                    {product.images && product.images.length > 0 && product.images[0].image ? (
                        <img
                            src={product.images[0].image}
                            alt="Product"
                            onError={handleImageError}
                        />
                    ) : (
                        <img
                            src={defaultImage}
                            alt="Default Product"
                        />
                    )}

                </div>
                )}
                <div className={" home-golden " + (isWindowClicked && 'home-golden2')}>   {truncateName(product.product_name)}
                    <div className={" home-price " + (isWindowClicked && 'home-golden2')}>${product.variation_attributes.price}</div>
                </div>


                {token && <div className="fav-add-col" onClick={() => { navigate(`/details/${product.product_id}`, { state: { pathname, title } }); }}>
                    ADD TO CART
                </div>}
                {!token && (
                    <div className="fav-add-col" onClick={() => { navigate(`/details/${product.product_id}`) }}>
                        ADD TO CART
                    </div>
                )}
                {copied && !isWindowClicked && <span className='details-link'>Link copied!</span>}


            </div>
            {isWindowClicked && (
                <div className="fav-text-col">
                    <div className="fav-golden-col">{product.product_name}</div>
                    <div className="fav-65-col">${product.variation_attributes.price} </div>
                    <div className="fav-lorem-col fav-lorem-col-static" dangerouslySetInnerHTML={{ __html: product.description }} />
                    {copied && <span className='details-link-window'>Link copied!</span>}
                    <div className="love-share" >
                        <img src={product.isFavorite ? `${process.env.PUBLIC_URL}/assests/love3.svg` : `${process.env.PUBLIC_URL}/assests/love4.svg`} alt="" className={' fav-love-end'} onClick={(e) => { e.stopPropagation(); removeFromFav(product.product_id, product.variation_attributes.variation_value_id, product.isFavorite) }} />
                        <img src={`${process.env.PUBLIC_URL}/assests/link.svg`} alt="Copy link" className='fav-love2-end' style={{ cursor: 'pointer' }} onClick={(e) => { handleCopy(e, product.product_id); }} />

                    </div>

                </div>
            )}
        </div>
    )
}
